.no-notification{
    color: #343434;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.6px;
    line-height: 35px;
    text-align: center;
}
.unread_countnot{
    color: #F1823B;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    width: 100%;
    float: left;
    padding-bottom:10px;
}