.welcome-div{
    background: #fff;
    padding: 30px 40px;
}

.welcome-name{
    color: #212121;
    
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.8px;
    line-height: 29px;
    padding-bottom: 10px;
    text-transform:uppercase;
}

.welcome-text{
    color: #606060;
    
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.6px;
    line-height: 22px;
}

.input-heading{
    color: #404040;
    
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.6px;
    line-height: 22px;
}

.input-field-class{
    text-align: left;
    outline: none;
    width: 100%;
    padding-left: 15px;
    font-size: 16px;
    height: 50px;
    border: 1px solid #EEEEEE;
    margin-bottom: 2%;
    font-weight: 500;
    padding-right: 13%;
    margin-top: 2%;
}

.location_symbol_wrap{
    position: absolute;
    right: 2%;
    top:47%;
    text-align: center;
    line-height: 45px;
    height: 50px;
    width: 70px;
    border: 1px solid #EEEEEE;
    background-color: #242e4926;
}
.go-back-buttonarabic{
    box-sizing: border-box;
    padding: 15px;
    width: 200px;
    border: 1px solid #242E49;
    color: #242E49;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.6px;
    line-height: 22px;
    text-align: center;
    background-color: #FFFFFF;
    cursor: pointer;
    float:left;
}
.button-wrap-div{
    width: 100%;
    margin-top: 3%;
    padding-bottom: 0;
}

.error-div{
    color: red;
    font-size: 14px;
    width: 100%;
    float: left;
    text-align: left;
}
@media screen and (min-width: 768px) and (max-width: 991px){
    .editprf_resp{
        padding-top: 10px;
        text-align: center;
        padding-bottom: 7%;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%!important;
    }
    .welcome-text{
        width: 100%;
        text-align: justify;
    }
    .welcome-name{
        text-align: center;
    }
    .prfdet_resp{
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%!important;
    }
}

@media (min-width: 320px) and (max-width: 575px){
.update-details-modal{
    width: 90% !important;
}
.editprf_resp{
    padding-top: 10px;
    text-align: center;
    padding-bottom: 7%;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%!important;
}
.welcome-text{
    width: 100%;
    text-align: justify;
}
.welcome-name{
    text-align: center;
}
.prfdet_resp{
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%!important;
}
.welcome-div{
    padding: 30px 0px;
}
.input-field-class{
    text-align: left;
    outline: none;
    width: 100%;
    padding-left: 15px;
    font-size: 16px;
    height: 50px;
    border: 1px solid #EEEEEE;
    margin-bottom: 2%;
    font-weight: 500;
    padding-right: 28%;
    margin-top: 2%;
}
}