.lets-get-started-wrap .lets-get-started {
    background-image: url("https://s3.ap-south-1.amazonaws.com/niuli-images/1603648029708-niuli-lets-get-started.jpg");
    width: 100%;
    height: 430px;
    padding: 9%;
    background-size: cover;
    background-repeat: no-repeat;
}


