.design-kitchen {
  background-image: url("../../images//Rectangle.png");
  margin: 3% 0;
}

.design-kitchen-padding {
  padding: 3% 25% 88px 25%;
}

.design-kitchen-with-error-padding {
  padding: 3% 25% 60px 25%;
}

.design-kitchen .kitchen-image-div {
  text-align: center;
  border: 1px solid #1f2128;
  border-radius: 8.33px;
  margin-top: 10%;
  padding: 30px 0 55px 0;
  margin-bottom: 8%;
}

.design-kitchen .kitchen-title {
  color: #212121;
  
  font-size: 16px;
  letter-spacing: 1.6px;
  line-height: 19px;
  text-transform: uppercase;
}

.design-kitchen .kitchen-slider .ant-slider-handle {
  margin-top: -6px;
  background-color: #242e49;
  border: solid 2px #ffffff;
  width: 22px;
  height: 22px;
}

.design-kitchen .kitchen-slider .ant-slider-step {
  background-color: #242e49;
  border-radius: 5px;
  height: 10px;
}

.design-kitchen .kitchen-slider .ant-slider {
  height: 10px !important;
  border-radius: 5px !important;
  background-color: #242e49 !important;
}

.design-kitchen .sliver-value-div {
  color: #3d3c3c;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  float: left;
  right: 16%;
  bottom: 28px;
  padding-left: 30px;
  padding-top: 3px;
}
.design-kitchen .sliver-value-divar {
  color: #3d3c3c;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  float: left;
  right: 16%;
  bottom: 28px;
  padding-left: 75px;
  padding-top: 3px;
}

.design-kitchen .slider-div {
  width: 49%;
  margin-left: 20%;
  float: left;
}

.design-kitchen .slider-divar {
  width: 55%;
  margin-left: 3%;
  float: left;
}

.design-kitchen .shape-image-div {
  width: 150px;
  object-fit: contain;
  height: 150px;
}

.design-kitchen .kitchen-shape {
  height: 203.13px;
  border-radius: 8.33px;
  background-color: #ffffff;
  text-align: center;
  padding: 9% 10%;
  cursor: pointer;
}
.design-kitchen .kitchen-shape:hover {
  box-shadow: 0 2px 52px 0 rgba(0, 0, 0, 0.12);
}
.design-kitchen .kitchen-shape-title {
  color: #212121;
  
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 2.51px;
  line-height: 53px;
  text-align: center;
}
.design-kitchen .tick-div-class {
  position: absolute;
  right: 0;
  top: -12px;
}

.design-kitchen .tick-div-class-arabic {
  right: auto;
  left: 0;
}
.starRed {
  color: #ff0000;
}
@media (max-width: 1024px) {
  .design-kitchen {
    padding: 3% 15% 80px 15%;
  }
}

@media (max-width: 425px) {
  .design-kitchen {
    padding: 19% 7% 25px 7%;
  }
  .design-kitchen .sliver-value-div {
    margin-left: 17%;
  }
  .design-kitchen .sliver-value-divar {
    margin-left: 17%;
  }
  .design-kitchen .slider-div {
    margin-left: 24%;
  }
  .design-kitchen .slider-divar {
    margin-left: 24%;
  }
  .design-kitchen .kitchen-image-div {
    text-align: center;
    border: 1px solid #1f2128;
    border-radius: 8.33px;
    margin-top: 10%;
    padding: 30px 0 95px 0;
    margin-bottom: 8%;
  }
  .imgnew_resfix {
    padding: 0px !important;
  }
}

@media (min-width: 1280px) and (max-width: 1365px) {
  .design-kitchen {
    padding: 3% 20% 88px 20%;
  }
}
