.project-list-item {
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    display: block;
    margin-bottom: 30px;
    position: relative;
    display: flex;
    align-items: center;
}

.project-list-item .img {
    width: 45%;
    height: 250px;
    float: left;
    position: relative;
    overflow: hidden;
}

.photo {
    height: 100%;
    width: 100%;
}

.project-list-item .text {
    background: #fff;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    width: 55%;
    height: 250px;
    right: 0px;
    position: absolute;
    z-index: 2;
    text-align: left;
    padding: 30px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.project-list-item .text-arabic-product {
    left: 0px;
    text-align: right;
    right: auto;
}
.project-list-item .text h3 {
    font-weight: 400;
    margin-bottom: 10px;
    color: #1F2128;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.6px;
    line-height: 19px;
}

.project-list-item .text p {
    margin-bottom: 0px;
    font-weight: 400;
    color: #606060;
    font-size: 16px;
    letter-spacing: 1.6px;
    line-height: 19px;
}

.product-price {
    color: #242E49 !important;
    font-size: 22px !important;
    font-weight: 500 !important;
    letter-spacing: 1.6px !important;
    line-height: 27px !important;
    text-align: right !important;
    margin-top: 20px !important;
}

.project-list-item:hover .text {
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.2);
}
@media (min-width: 320px) and (max-width: 575px){
    .project-list-item .text{
        height: 410px;
    }
    .choose-kitchen-wrap .project-list-item{
        height: 420px;
    }
}