@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap);
.no-notification{
    color: #343434;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.6px;
    line-height: 35px;
    text-align: center;
}
.unread_countnot{
    color: #F1823B;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    width: 100%;
    float: left;
    padding-bottom:10px;
}
.no_items{
    text-align: center;
}
.disignmilestonecolor{
    color:#F1823B;
  }
  .productionmilestonecolor{
    color:#9347D6;
  }
  .fittingmilestone{
    color:#3B83F1;
  }
  .kitchenreadymilestone{
    color:#72B968;
  }

  .kitchen-button { 
    margin-left: 'auto'; 
    border-radius: '3px'; 
    color: '#fff'; 
    border: 'none'; 
    border: '1px solid #fff'; 
    margin:'5px'; 
    background-color: 'rgb(35 41 66)'
  }
  .kitchen-button:hover {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.9);
  }
  .kitchen-buttonClick { 
    margin-left: 'auto'; 
    border-radius: '3px solid black'; 
    color: '#000';  
    border: '1px solid #000'; 
    margin:'5px'; 
    background-color: '#fff'
  }

  .kitchen-buttonClick { 
    color: #fff;
    background-color: rgba(0, 0, 0, 0.9);
  }

  @media (min-width: 320px) and (max-width: 575px){
  .order_resp_fix{
    width: 50px;
    height: 50px;
    margin-top: 7px;
    }
    .orders_center{
      text-align: center;
    }
  .dupBtn{
    display: flex;
  }
  }

  /*  */
.app_stdfitter{
    width: 100%;
    float: left;
    padding: 10% 15% 9% 15%;
}
.app_btns{
  text-align: center;
  width: 100%;
}
.app_stdfwrapinside{
    color: #242E49;
    text-align: center;
    width: 100%;
    padding-top: 10%;
    float: left;
    font-weight: 600;
    padding-bottom: 10%;
}
.draft_wrapfitting{
  padding: 0% 13% 0% 13%;
  width: 100%;
  float: left;
  background: #F6F6F6;
}
.mopitstop1{
  width:50%;
  margin-top: 10px;
}
.mopitstop1 .ant-modal-body{
  padding:0px;
}
.mopitstop1 .ant-modal-close{
  outline: none;
  border: none;
}
.mopitstop1 .ant-modal-close-x{
  color: #000;
  font-weight: 600;
  font-size: 30px;
}
.mopitstop1 .ant-modal-wrap{
  position: fixed;
  top: 30%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}

/* for repeat order */

.mopitstop1_repeat{
  width:50% !important;
  margin-top: 10px;
}
.mopitstop1_repeat .ant-modal-body{
  padding:0px;
}
.mopitstop1_repeat .ant-modal-close{
  outline: none;
  border: none;
}
.mopitstop1_repeat .ant-modal-close-x{
  color: #000;
  font-weight: 600;
  font-size: 30px;
}
.mopitstop1_repeat .ant-modal-wrap{
  position: fixed;
  top: 30%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}

.mopitstop1_repeat .ant-modal-body .app_stdfitter {
  padding: 10% 2% 9% 2% !important;
}

.mopitstop1_repeat .ant-modal-body .app_stdfitter .app_stdfwrapinside {
  font-size: 20px;
}
.lets-get-started-wrap .lets-get-started {
    background-image: url("https://s3.ap-south-1.amazonaws.com/niuli-images/1603648029708-niuli-lets-get-started.jpg");
    width: 100%;
    height: 430px;
    padding: 9%;
    background-size: cover;
    background-repeat: no-repeat;
}



.project-list-item {
    transition: all 0.3s ease 0s;
    display: block;
    margin-bottom: 30px;
    position: relative;
    display: flex;
    align-items: center;
}

.project-list-item .img {
    width: 45%;
    height: 250px;
    float: left;
    position: relative;
    overflow: hidden;
}

.photo {
    height: 100%;
    width: 100%;
}

.project-list-item .text {
    background: #fff;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    width: 55%;
    height: 250px;
    right: 0px;
    position: absolute;
    z-index: 2;
    text-align: left;
    padding: 30px;
    transition: all 0.3s ease 0s;
}

.project-list-item .text-arabic-product {
    left: 0px;
    text-align: right;
    right: auto;
}
.project-list-item .text h3 {
    font-weight: 400;
    margin-bottom: 10px;
    color: #1F2128;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.6px;
    line-height: 19px;
}

.project-list-item .text p {
    margin-bottom: 0px;
    font-weight: 400;
    color: #606060;
    font-size: 16px;
    letter-spacing: 1.6px;
    line-height: 19px;
}

.product-price {
    color: #242E49 !important;
    font-size: 22px !important;
    font-weight: 500 !important;
    letter-spacing: 1.6px !important;
    line-height: 27px !important;
    text-align: right !important;
    margin-top: 20px !important;
}

.project-list-item:hover .text {
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.2);
}
@media (min-width: 320px) and (max-width: 575px){
    .project-list-item .text{
        height: 410px;
    }
    .choose-kitchen-wrap .project-list-item{
        height: 420px;
    }
}
.choose-kitchen-wrap{
    background: #F6F6F6;
    margin: 3% 0;
}

.choose-kitchen-wrap-padding{
    padding: 5% 10% 51px 10%;
}

.choose-kitchen-wrap-with-error-padding{
    padding: 5% 10% 23px 10%;
}

.choose-kitchen-wrap .tick-div-class{
    position: absolute;
    right: 0;
    top: 17px;
    z-index: 99;
}

.choose-kitchen-wrap .tick-div-class-arabic{
    right: auto;
    left: 0;
}

.choose-kitchen-wrap .project-list-item {
    transition: all 0.3s ease 0s;
    display: block;
    margin-bottom: 30px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.choose-kitchen-wrap .button-wrap{
    margin-top: 60px;
    padding-bottom: 0px;
    width: 100%;
}

@media (max-width: 425px) {
    .choose-kitchen-wrap{
        padding: 20% 5% 60px 5%;
    }
    .resp_gobkbtn{
        float: none !important;
    }
}

.design-kitchen {
  background-image: url(/static/media/Rectangle.62214665.png);
  margin: 3% 0;
}

.design-kitchen-padding {
  padding: 3% 25% 88px 25%;
}

.design-kitchen-with-error-padding {
  padding: 3% 25% 60px 25%;
}

.design-kitchen .kitchen-image-div {
  text-align: center;
  border: 1px solid #1f2128;
  border-radius: 8.33px;
  margin-top: 10%;
  padding: 30px 0 55px 0;
  margin-bottom: 8%;
}

.design-kitchen .kitchen-title {
  color: #212121;
  
  font-size: 16px;
  letter-spacing: 1.6px;
  line-height: 19px;
  text-transform: uppercase;
}

.design-kitchen .kitchen-slider .ant-slider-handle {
  margin-top: -6px;
  background-color: #242e49;
  border: solid 2px #ffffff;
  width: 22px;
  height: 22px;
}

.design-kitchen .kitchen-slider .ant-slider-step {
  background-color: #242e49;
  border-radius: 5px;
  height: 10px;
}

.design-kitchen .kitchen-slider .ant-slider {
  height: 10px !important;
  border-radius: 5px !important;
  background-color: #242e49 !important;
}

.design-kitchen .sliver-value-div {
  color: #3d3c3c;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  float: left;
  right: 16%;
  bottom: 28px;
  padding-left: 30px;
  padding-top: 3px;
}
.design-kitchen .sliver-value-divar {
  color: #3d3c3c;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  float: left;
  right: 16%;
  bottom: 28px;
  padding-left: 75px;
  padding-top: 3px;
}

.design-kitchen .slider-div {
  width: 49%;
  margin-left: 20%;
  float: left;
}

.design-kitchen .slider-divar {
  width: 55%;
  margin-left: 3%;
  float: left;
}

.design-kitchen .shape-image-div {
  width: 150px;
  object-fit: contain;
  height: 150px;
}

.design-kitchen .kitchen-shape {
  height: 203.13px;
  border-radius: 8.33px;
  background-color: #ffffff;
  text-align: center;
  padding: 9% 10%;
  cursor: pointer;
}
.design-kitchen .kitchen-shape:hover {
  box-shadow: 0 2px 52px 0 rgba(0, 0, 0, 0.12);
}
.design-kitchen .kitchen-shape-title {
  color: #212121;
  
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 2.51px;
  line-height: 53px;
  text-align: center;
}
.design-kitchen .tick-div-class {
  position: absolute;
  right: 0;
  top: -12px;
}

.design-kitchen .tick-div-class-arabic {
  right: auto;
  left: 0;
}
.starRed {
  color: #ff0000;
}
@media (max-width: 1024px) {
  .design-kitchen {
    padding: 3% 15% 80px 15%;
  }
}

@media (max-width: 425px) {
  .design-kitchen {
    padding: 19% 7% 25px 7%;
  }
  .design-kitchen .sliver-value-div {
    margin-left: 17%;
  }
  .design-kitchen .sliver-value-divar {
    margin-left: 17%;
  }
  .design-kitchen .slider-div {
    margin-left: 24%;
  }
  .design-kitchen .slider-divar {
    margin-left: 24%;
  }
  .design-kitchen .kitchen-image-div {
    text-align: center;
    border: 1px solid #1f2128;
    border-radius: 8.33px;
    margin-top: 10%;
    padding: 30px 0 95px 0;
    margin-bottom: 8%;
  }
  .imgnew_resfix {
    padding: 0px !important;
  }
}

@media (min-width: 1280px) and (max-width: 1365px) {
  .design-kitchen {
    padding: 3% 20% 88px 20%;
  }
}

.app_stdcarousel{
    width:100%;float:left;
}
/* .app_stdcarousel .thumb .dehi_carouselimagewrapper{
    height:90px;
    width: 115px;
    margin-bottom: 12px;
}
.app_stdcarousel .thumb{
    width:auto!important;border:none!important;
}
.app_stdcarousel .slider-wrapper{
    height:363px;
} */

.vp-center{
  background: rgba(0, 0, 0, 0.85) !important;
}

.play-button {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 60px;
    width: 100px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 5px;
  }
  
  .play-button:hover {
    background-color: rgba(0, 0, 0, 0.9);
  }
  
  .play-button:after {
    content: "";
    display: block;
    position: absolute;
    top: 16.5px;
    left: 40px;
    margin: 0 auto;
    border-style: solid;
    border-width: 12.5px 0 12.5px 20px;
    border-color: transparent transparent transparent rgba(255, 255, 255, 1);
  }
  
  .close-video::before {
    content: '\2716';
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 20px;
    padding: 20px;
    z-index: 1;
    line-height: .7;
    display: block;
    color: #fff;
  }
  
  .video-wrapper {
    position: relative;
    padding:21%; /* 16:9 */
    height: 0;
  }
  
  .video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:100%;
    margin-left: auto;
    text-align: center;
    margin-right: auto;
  }
  
  
  .app .image-gallery,
  .app-sandbox { 
    margin: 0 auto;
    width: 65%;
    transition: all 1s ease;
  }
  .app-image-gallery .image-gallery-content{
    padding-top: 5%;
  }
  .app-image-gallery .image-gallery-slide .image-gallery-image{
    width: unset;
    object-fit: cover;
    /* object-fit: fill; */
    max-width: 80%;
  }
  .find_imgs .app-image-gallery .image-gallery-slide .image-gallery-image{
    width: unset;
    object-fit: cover;
    /* object-fit: fill; */
    max-width: 100%;
  }
  /* .app-image-gallery .image-gallery-slide .image-gallery-image{
        width: unset;
    object-fit: fill;
    max-width: 80%;
  } */
  .app-image-gallery .image-gallery-content.left .image-gallery-slide .image-gallery-image{
    max-height: 90vh!important;
  }
  .statussec_onecardpro .app-image-gallery .image-gallery-content{
    padding-top:0%;
  }
  .statussec_onecardpro .app-image-gallery .image-gallery-slide .image-gallery-image{
    width: 100%;
    object-fit: contain;
    max-width: 100%;
  }
  @media (max-width: 1320px) {
    .app-sandbox-content {
      padding: 0 20px;
    }
  }
  
  .app-sandbox {
    margin: 40px auto;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  
  .app-buttons li {
    display: block;
  }
  
  @media (max-width: 768px) {
  
    .app-header {
      font-size: 20px;
    }
  
    .app-buttons li {
      display: block;
      margin: 10px 0;
    }
  
    .app-buttons li + li {
      padding: 0;
    }
  
    .play-button {
      height: 40px;
      width: 65px;
    }
  
    .play-button:after {
      top: 11px;
      left: 27px;
      border-width: 8.5px 0 8.5px 12px;
    }
  
    .close-video::before {
      font-size: 16px;
      padding: 15px;
    }
    .app-image-gallery .image-gallery-slide .image-gallery-image{
      max-width: 95%;
    }
  }
  
  @media (max-width: 1024px) {
    .app .image-gallery,
    .app-sandbox {
      width: 100%;
    }
  }
  
  .app-interval-input-group {
    display: table;
  }
  
  .app-interval-label {
    display: table-cell;
    vertical-align: middle;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 3px solid #ccc;
    border-right: none;
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .app-interval-input {
    -webkit-appearance: none;
    display: table-cell;
    margin: 0;
    padding: 9px;
    border-radius: 5px;
    font-size: 14px;
    border: 3px solid #ccc;
    background: #fff;
    width: 100%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  input.app-interval-input {
    width: 65px;
  }
  
  .app-checkboxes {
    margin-top: 10px;
  }
  
  .app-checkboxes li {
    display: block;
  }
.draft_wrapinnerdes{
    color: #606060;
    font-size: 18px;
    text-align: center;
    width: 100%;
    float: left;
    padding-top: 7px;
}
.draft_wrapitime{
    width: 100%;
    float: left;
    text-align: center;
    color: #2B2B2B;
    font-size: 17px;
    font-weight: 600;
    padding-bottom: 6%;
}
.designer_nameappmeet{
    color: #606060;
    font-size: 15px;
    width: 100%;
    float: left;
    padding-top: 10%;
    padding-bottom: 5%;
    font-weight: 500;
}
.designer_namecall{
    color: #606060;
    font-size: 15px;
    width: 100%;
    float: left;
    padding-top: 5%;
    font-weight: 500;
    margin-bottom: 7%;
}
.call_phoneno{
    padding-right: 13px;
}
.call_phonenoar{
    padding-left: 13px;
}
.meetdes_app{
    margin-bottom: 5%;
}
.designer_modalch .app-image-gallery .image-gallery-slide .image-gallery-image{
    width: 100%;
    object-fit: cover;
    max-width: 100%;
}
.app_std{
    width:100%;float:left;
}

.mopitstop{
    width:85%!important;
}
.mopitstop .ant-modal-body{
    padding:0px;
}

.remvapp_padng{
    padding:0px;
}
.mopitstop .ant-modal-close{
    outline: none;
    border: none;
}

.inti_Scrennap{
    color:#212121;
    width:100%;float:left;text-align: center;
}

.draft_wrapinner{
    color: #606060;
    font-size: 18px;
    text-align: center;
    width: 100%;
    float: left;
    padding-top: 7px;
    padding-bottom:5%;
}
.mopitstop .ant-modal-close-x{
    color: #000;
    font-weight: 600;
    font-size: 30px;
}

.app_designleft{
    width:100%;float:left;padding:20px;
    box-shadow: 1px 3px 12px -2px rgba(0,0,0,0.35);
    background-image:linear-gradient(#ffd8ba29,#ffffff);
    text-align: center;
}

.draft_wrapdesign_img{
    padding: 4% 15% 5% 15%;
    width: 100%;
    float: left;
    background: #F6F6F6;
}
.designer_nameinner{
    color: #212121;
    font-size: 17px;
    font-weight: bold;
    float: left;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 6px;
    text-transform: capitalize;
}
.designer_nameappexp{
    color: #212121;
    font-size: 15px;
    width: 100%;
    float: left;
}
.designer_nameapptxt{
    color: #606060;
    font-size: 15px;
    width: 100%;
    float: left;
    padding-top: 10%;
    padding-bottom: 15%;font-weight: 500;
}
.starsec{
    padding-left: 13px;
}
.starsecar{
    padding-right: 13px;
}
.star_inner{
    padding-right: 8px;
    margin-top: -5px;
}
.star_innerar{
    padding-left: 8px;
    margin-top: -5px;
}
.designer-profile-image{
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

.video_secplay{
    position: absolute;
    top: 130px;
    right: 128px;
}
.box_applfix{
    right: 27px;
    position: absolute;
    bottom: 54px;
    float: left;
}
.app_stdrate_prof{
    width:100%;float:left;background: #fff;padding:20px;margin-top: 4%;
    margin-bottom: 3%;
}
.rate_theprof{
    color: #313131;
    float: left;
    width: 100%;
    font-size: 15px;
    font-weight: 500;
}
.prf_nameratg{
    float: right;
    color: #606060;
    font-size: 15px;
    font-weight: 500;
    padding-top: 15px;
}
.prf_nameratgstar{
    float: left;
    color: #606060;
    font-size: 15px;
    font-weight: 500;
    padding-top: 8px;
}
.dark-btnorderatgdsgn {
    display: block;
    width:285px;
    height: 45px;
    position: relative;
    overflow: hidden;
    border: 1px solid #242E49;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 20px;
  }
  
  .dark-btnorderatgdsgn .show-btnorderatgdsgn {
    position: absolute;
    top: 0px;
    cursor: pointer;
    left: 0px;
    width: 285px;
    height: 45px;
    line-height: 42px;
    text-align: center;
    transition: all 0.3s ease 0s;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 1.35px;
    color: #242E49;
  }
  
  .dark-btnorderatgdsgn .hide-btnorderatgdsgn {
    position: absolute;
    top: 0px;
    cursor: pointer;
    left: 285px;
    width: 285px;
    height: 45px;
    line-height: 42px;
    text-align: center;
    transition: all 0.3s ease 0s;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 1.35px;
    color: #fff;
    background: #242E49;
  }
  
  .dark-btnorderatgdsgn:hover .show-btnorderatgdsgn {
    left: -195px;
  }
  
  .dark-btnorderatgdsgn:hover .hide-btnorderatgdsgn {
    left: 0px;
  }
  .tel_appokj{
    color: #606060;
    font-size: 15px;
    font-weight: 500;
  }
  .tel_appokj:hover{
    color: #606060;
    font-size: 15px;
    font-weight: 500;
  }
  @media (min-width: 320px) and (max-width: 480px){
    .draft_wrapdesign_img{ 
        padding: 19% 3% 15% 3%;
    }
.app_designleft{
    margin-bottom: 10%;
    margin-top: 5%;
    }
    .dark-btnorderatgdsgn{
        margin-bottom: 20px;
    }
    .video_secplay{
        top: 235px;
    }
    .box_applfix{
        display:none;
    }
  }
  @media (min-width: 320px) and (max-width: 575px){
    .draft_wrapdesign_img{ 
        padding: 19% 3% 15% 3%;
    }
    .app_designleft{
        margin-bottom: 10%;
        margin-top: 5%;
    }
    .dark-btnorderatgdsgn{
        margin-bottom: 20px;
    }
    .video_secplay{
        top: 235px;
    }
    .box_applfix{
        display:none;
    }
}
  @media screen and (min-width: 768px) and (max-width: 991px) {
    .draft_wrapdesign_img{
        padding: 8% 0% 8% 0%;
    }
  }
.consultation-confirmation{
    padding: 5% 0 126px 0;
    background-color: #F6F6F6;
}

.consultation-confirmation .timer-div{
    width: 274px;
    padding: 2%;
    text-align: center;
    margin: 6% auto 1% auto;
    background-color: #FFFFFF;
    box-shadow: 0 2px 64px 0 rgba(0,0,0,0.12);
    color: #242E49;
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 20px;
    text-align: center;
}

.thank-you-tick-class{
    width: 100%;
    float: left;
    text-align: center;
    padding: 20px;
}

.thank-you{
    width: 70px;
    height: 70px;
}

.rai-wave{
    -webkit-animation-name: rai-sentry-pulse !important;
            animation-name: rai-sentry-pulse !important;
    -webkit-animation-duration: inherit !important;
            animation-duration: inherit !important;
    -webkit-animation-timing-function: linear !important;
            animation-timing-function: linear !important;
    -webkit-animation-iteration-count: infinite !important;
            animation-iteration-count: infinite !important;
}


@media (max-width: 425px) {
    .consultation-confirmation{
        padding: 19% 15px 170px 15px;
        background-color: #F6F6F6;
    }
    .consultation-confirmation .timer-div{
        padding: 15px;
        width: 200px;
        float: left;
    }
}
.more-infornmation-wrap {
  padding: 5% 15% 0% 15%;
}

.more-infornmation-wrap .button-wrap {
  margin-top: 80px;
  width: 100%;
}

.button-wrap-padding {
  padding-bottom: 50px;
}

.button-wrap-padding-with-error {
  padding-bottom: 18px !important;
}

.more-infornmation-wrap .site-info-class {
  color: #212121;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  position: relative;
}

.list-padding-arabic {
  padding-left: 0px;
  padding-right: 30px;
}

.list-padding-arabic:hover {
  padding-right: 40px;
}

.long-arrow-class-more-info {
  left: 31px;
  position: absolute;
}

.long-arrow-header {
  left: -25px;
  position: absolute;
  top: 5px;
}

.long-arrow-left-class-more-info {
  right: 42px;
  position: absolute;
}

.long-arrow-left-header {
  right: -25px;
  position: absolute;
  top: 5px;
}
.new-site .ant-radio-inner {
  width: 24px;
  height: 24px;
  border-color: #1f2128;
  background-color: #f6f6f6;
  top: 2px;
}

.new-site .ant-radio-inner::after {
  width: 16px;
  height: 16px;
  background-color: #1f2128;
}

.old-site .ant-radio-inner {
  width: 24px;
  height: 24px;
  border-color: #1f2128;
  top: 2px;
  background-color: #f6f6f6;
}

.old-site .ant-radio-inner::after {
  width: 16px;
  height: 16px;
  background-color: #1f2128;
}

.old-site,
.ant-radio-wrapper {
  color: #212121;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.6px;
  line-height: 19px;
}

.new-site,
.ant-radio-wrapper {
  color: #212121;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.6px;
  line-height: 19px;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #1f2128 !important;
}

.budget-select {
  border: 1px solid #242e49 !important;
  border-right-width: 2px solid #242e49 !important;
  background-color: #ffffff;
}

.budget-select,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border: none;
  border-right-width: 2px solid #242e49 !important;
}

.budget-select,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
  border-right-width: 2px solid #242e49 !important;
}

.budget-select,
.ant-select-focused.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border: none;
  border-right-width: 2px solid #242e49 !important;
  outline: 0;
  box-shadow: none;
}

.budget-select .ant-select-selector .ant-select-selection-placeholder {
  color: #606060;
  
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.6px;
  opacity: 1;
}

.more-infornmation-wrap .slider-div {
  width: 62%;
  float: left;
}

.more-infornmation-wrap .kitchen-slider .ant-slider-handle {
  margin-top: -6px;
  background-color: #242e49;
  border: solid 2px #ffffff;
  width: 22px;
  height: 22px;
}

.more-infornmation-wrap .kitchen-slider .ant-slider-step {
  background-color: #242e49;
  border-radius: 5px;
  height: 10px;
}

.more-infornmation-wrap .kitchen-slider .ant-slider {
  height: 10px !important;
  border-radius: 5px !important;
  background-color: #242e49 !important;
}

.more-infornmation-wrap .sliver-value-div {
  color: #3d3c3c;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  float: left;
  right: 16%;
  bottom: 28px;
  padding-top: 10px;
  padding-left: 13px;
}
.more-infornmation-wrap .sliver-value-divar {
  color: #3d3c3c;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  float: left;
  right: 16%;
  bottom: 28px;
  padding-top: 10px;
  padding-right: 13px;
}

.more-infornmation-wrap .you-chose-card-info-screen {
  background-color: #ffffff;
  box-shadow: 0 2px 64px 0 rgba(0, 0, 0, 0.12);
  padding: 20px;
  margin-top: 20px;
}

.more-infornmation-wrap .you-chose-title {
  color: #212121;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.6px;
  line-height: 19px;
  text-transform: uppercase;
  padding-bottom: 25px;
}

.more-infornmation-wrap .chose-info {
  color: #606060;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.53px;
  line-height: 19px;
  padding-left: 30px;
}

.more-infornmation-wrap .chose-info-arabic {
  padding-right: 30px;
  padding-left: 0px !important;
}

.chose-info li {
  padding-bottom: 20px;
}

.chose-info li:hover {
  margin-left: 15px;
  transition: all 0.3s ease 0s;
}

/* .chose-info li::before { 
    content: url(../../images/back-icon.png);
    position: absolute;
    left: 39px;
} */
.ale_chana {
  float: left;
}
.ale_chanaAr {
  float: right;
}

@media (max-width: 1024px) {
  .more-infornmation-wrap {
    padding: 5% 10%;
  }
  .more-infornmation-wrap .button-wrap {
    padding-bottom: 00px;
  }
}

@media (max-width: 425px) {
  .more-infornmation-wrap {
    padding: 15% 10%;
  }
  .txt_fieldresp .ant-select-selector {
    width: 275px !important;
    float: left;
  }
  .txt_fieldresp .ant-select {
    width: 277px !important;
    float: left;
  }
}

.ready-to-meet-wrap {
    margin: 50px 0;
    padding: 5% 15% 50px 15%;
}

.ready-to-meet-padding{
padding: 5% 15% 50px 15%;
}

.ready-to-meet-with-error-wrap {
    padding: 5% 15% 20px 15%;
}


.ready-to-meet-wrap .meet-info-class {
    color: #212121;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.6px;
    text-transform: uppercase;
}

.ready-to-meet-wrap .location-alert{
    width: 100%;
    float: left;
    line-height: 0px;
    color: #ff2f00;
}

.ready-to-meet-wrap .button-wrap{
    margin-top: 30px;
    padding-bottom: 0px;
}

/* .ready-to-meet-wrap .meet-info-class::before {
    content: url(../../images/back-icon.png);
    position: absolute;
    left: -14px;
} */

.ready-to-meet-wrap .location-meet-up {
    width: 100%;
    float: left;
    padding-bottom: 1rem;
    /* line-height: 50px; */
}

.calender-class .react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    border-radius: 0;
    background-color: #fff;
    color: #212121;
}

.calender-class .react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
    background-color: #f0f0f0;
}

.calender-class .react-datepicker__header {
    background-color: #fff;
    border-bottom: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.calender-class,
.react-datepicker {
    background-color: #fff;
    border: none;
    color: #212121;
    
    font-size: 15px;
    letter-spacing: 2.09px;
    line-height: 18px;
    box-shadow: 0 2px 64px 0 rgba(0, 0, 0, 0.12);
    margin-top: 5%;
}

.calender-class .react-datepicker__current-month {
    color: #212121;
    
    font-size: 18px;
    letter-spacing: 2.51px;
    line-height: 70px;
}

.calender-class .react-datepicker__day-name {
    color: #606060 !important;
    
    font-size: 15px;
    letter-spacing: 2.09px;
    line-height: 18px;
}

.calender-class .react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: #212121;
    width: 40px;
    margin: 12px 20px;
}

.calender-class .react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
    color: #ccc !important;
}

.calender-class .react-datepicker__day--selected {
    background-color: #FFCF6C;
}


.calender-class .react-datepicker__navigation--next {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAUCAYAAABWMrcvAAAAAXNSR0IArs4c6QAAAVtJREFUOBF9krtKQ0EQho9GRbAVxEqCIGghXgsttdUyWIhY+QD6ImphY2fno9hGVFCCVzDgBfGCIATx+zbZwzHJyQ/fmZ3Zndmzs1tIkmQEdmAGLuAHOqrA7DYMwyBMwSl8Q65MGoJiY8UAdhbO4asRazEm+Usujon9jOfgEt6hRSapM+iGMR3UB/NwDa/wTzHJoJVtwoQO6gETH+AJUmWTDFr5DSahC5z3jCY9QlBzkkErV8FO+ssyDR9wB6GStlkm3YB3Z2F3dfcaVNrtRDzome8VmNgbIkkyjq26dSfZxebCNTuUJ8+0Bdk1x/hl/7WdFghuQpz/ZXwEJ5AGHUctMViLDtbDH0I5xmKl6K8wWI0O1ss+AC8+VUzSlmA5nam/9H3820wsDF1sBzdgMUTqHx/qHqSvIDMXOrNOIJvwgr8L2raynaOZGV+CCe6UKy+uAkW4Bw/9CR31B62KO75te/OgAAAAAElFTkSuQmCC) no-repeat;
    width: 20px;
    height: 30px;
    border: none;
    right: 35px;
}

.calender-class .react-datepicker__navigation--previous {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAUCAYAAABWMrcvAAAAAXNSR0IArs4c6QAAAVZJREFUOBGNkr1LQlEYxjUzIgolcigQoaEW7VNXZ9fWNmloCKL/paHJGtqkKWhqcGqKIAgSgqhBMqFAiIoiqd9zO0fPvfj1wu+e933e9+Gee+4Jh4aLGGNboPUwPIRnhpk9SJjZ+ugA06wxxJ25aj9TisFdmHQMFfJyr+0t0NyBccdwRn6qupspg74NUQ2YOGE9t0XQlKNRhIgZ+GU9hgtTe4tryqNsgtVa5CW4Al/YgQLqhtP5Jj+AW0drpzq9dXANn9T7cA9dYwT1J9DR94wFNF+pgQa8Q9p0pGXhCZ6N5ls0oHiEF1gGfad2IOMr1MAX1iRRTbEKMsm8Ah/wAO1wTRK1VR3AGuiQFHbbd/9l5yfaWqu2VAWdqr0Vi+QT4P2C4JvQvWjyvAFt1d6/efJp6b1M9EJvcA1LoLcokhDvZ9KQDkHXSN81BYroIJOGvuAS5kx+9Ac6vzPsAeQVrQAAAABJRU5ErkJggg==) no-repeat;
    width: 20px;
    height: 30px;
    border: none;
    left: 35px;
}

.calender-class .react-datepicker__navigation {
    top: 35px;
}

button:focus {
    border: none;
    outline: none;
}

.ready-to-meet-wrap .meet-time {
    width: 100%;
    float: left;
    text-align: center;
    margin-top: 50px;
}

.ready-to-meet-wrap .meet-prio {
    width: 100%;
    float: left;
    text-align: center;
}

.ready-to-meet-wrap .meet-time button {
    background-color: #FFFFFF;
    box-shadow: 0 2px 64px 0 rgba(0, 0, 0, 0.12);
    color: #202020;
    
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.4px;
    line-height: 17px;
    padding: 10px 20px;
    margin: 10px 15px;
    float: left;
    cursor: pointer;
    border: none;
}

.ready-to-meet-wrap .meet-prio div {
    background-color: #FFFFFF;
    box-shadow: 0 2px 64px 0 rgba(0, 0, 0, 0.12);
    color: #202020;
    
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.4px;
    line-height: 17px;
    padding: 10px 33px;
    margin: 10px 20px 10px 3px;
    float: left;
    cursor: pointer;
}



.ready-to-meet-wrap .date-time-wrap {
    width: 100%;
    float: left;
    padding-bottom: 30px;
}

.ready-to-meet-wrap .time-class:hover{
    background: rgb(255 207 108 / 52%);
}

.ready-to-meet-wrap .location-search {
    float: left;
    outline: none;
    width: 100%;
    padding-left: 15px;
    padding-right: 90px;
    font-size: 16px;
    height: 50px;
    border: 1px solid #EEEEEE;
    margin-bottom: 3%;
    font-weight: 500;
    margin-top: 3%;
}

.ready-to-meet-wrap .location-searchar {
    float: left;
    outline: none;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 16px;
    height: 50px;
    border: 1px solid #EEEEEE;
    margin-bottom: 3%;
    font-weight: 500;
    margin-top: 3%;
}

.ready-to-meet-wrap .refernce-code{
    float: left;
    outline: none;
    width: 74%;
    padding-left: 15px;
    font-size: 16px;
    height: 50px;
    border: 1px solid #EEEEEE;
    margin-bottom: 3%;
    font-weight: 500;
}

.ready-to-meet-wrap .location-symbol {
    position: absolute;
    right: 0%;
    top: 22%;
    text-align: center;
    line-height: 45px;
    height: 50px;
    width: 70px;
    border: 1px solid #EEEEEE;
    background-color: #242e4926;
}

.ready-to-meet-wrap .you-chose-card-info-screen {
    background-color: #FFFFFF;
    box-shadow: 0 2px 64px 0 rgba(0, 0, 0, 0.12);
    padding: 20px;
    margin-top: 20px;
}

.ready-to-meet-wrap .you-chose-title {
    color: #212121;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.6px;
    line-height: 19px;
    text-transform: uppercase;
    padding-bottom: 25px;
}

.ready-to-meet-wrap .you-chose-edit {
    float: right;
    color: blue;
    cursor: pointer;
}

.ready-to-meet-wrap .chose-info {
    color: #606060;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.53px;
    line-height: 19px;
    padding-left: 30px;
}

.ready-to-meet-wrap .chose-info-arabic{
    padding-right: 30px;
    padding-left: 0px !important;
}

.chose-info li {
    padding-bottom: 20px;
}

.chose-info li:hover {
    margin-left: 15px;
    transition: all 0.3s ease 0s;
}

/* .chose-info li::before {
    content: url(../../images/back-icon.png);
    position: absolute;
    left: 39px;
} */

@media (max-width: 1024px) {
    .ready-to-meet-wrap {
        padding: 5% 10%;
    }

    .calender-class .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        margin: 10px 10px;
    }
}

@media (max-width: 425px) {
    .ready-to-meet-wrap {
        padding: 18% 5%;
    }
    .calender-class .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        margin: 10px 0px;
    }
    .ready-to-meet-wrap .meet-info-class{
        padding-left: 25px;
    }
    /* .ready-to-meet-wrap .meet-info-class::before{
        left: 18px;
    } */
    .ready-to-meet-wrap .location-symbol{
        right: 0%;
    }
    .ready-to-meet-wrap .location-search{
        width: 100%;
    }
    .ready-to-meet-wrap .location-searchar{
        width: 100%;
    }

    .ready-to-meet-wrap .refernce-code{
        width: 100%;
    }
}

@media (min-width: 1280px) and (max-width: 1365px){
    .calender-class .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        width: 30px;
        margin: 10px 15px;
    }
}
/* .gm-style-iw-c{
    display: none
} */
.long-arrow-left-header-location{
    right: -25px;
    position: absolute;
    top:6px;
}

.ready-to-meet-wrap .meet-info-class {
    color: #212121;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.6px;
    text-transform: uppercase;
}

.ready-to-meet-wrap .you-chose-edit {
    float: right;
    color: blue;
    cursor: pointer;
}
.design-non-kitchen {
    background-image: url(/static/media/Rectangle.62214665.png);
    margin: 3% 0;
  }
  
  .non-kitchen-title {
    margin: 5px;
  }
  .design-non-kitchen-padding {
    padding: 3% 25% 88px 25%;
  }
  
  .design-non-kitchen-with-error-padding {
    padding: 3% 25% 60px 25%;
  }
  
  .design-non-kitchen .kitchen-image-div {
    text-align: center;
    border: 1px solid #1f2128;
    border-radius: 8.33px;
    margin-top: 10%;
    padding: 30px 0 55px 0;
    margin-bottom: 8%;
  }
  
  .design-non-kitchen .kitchen-title {
    color: #212121;
    
    font-size: 16px;
    letter-spacing: 1.6px;
    line-height: 19px;
    text-transform: uppercase;
  }
  
  .design-non-kitchen .kitchen-slider .ant-slider-handle {
    margin-top: -6px;
    background-color: #242e49;
    border: solid 2px #ffffff;
    width: 22px;
    height: 22px;
  }
  
  .design-non-kitchen .kitchen-slider .ant-slider-step {
    background-color: #242e49;
    border-radius: 5px;
    height: 10px;
  }
  
  .design-non-kitchen .kitchen-slider .ant-slider {
    height: 10px !important;
    border-radius: 5px !important;
    background-color: #242e49 !important;
  }
  
  .design-non-kitchen .sliver-value-div {
    color: #3d3c3c;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
    float: left;
    right: 16%;
    bottom: 28px;
    padding-left: 30px;
    padding-top: 3px;
  }
  .design-non-kitchen .sliver-value-divar {
    color: #3d3c3c;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
    float: left;
    right: 16%;
    bottom: 28px;
    padding-left: 75px;
    padding-top: 3px;
  }
  
  .design-non-kitchen .slider-div {
    width: 49%;
    margin-left: 20%;
    float: left;
  }
  
  .design-non-kitchen .slider-divar {
    width: 55%;
    margin-left: 3%;
    float: left;
  }
  
  .design-non-kitchen .shape-image-div {
    width: 150px;
    object-fit: contain;
    height: 150px;
  }
  
  .design-non-kitchen .kitchen-shape {
    /* height: 203.13px; */
    border-radius: 8.33px;
    background-color: #ffffff;
    text-align: center;
    padding: 9% 10%;
    cursor: pointer;
    margin: 5px;
  }
  .design-non-kitchen .kitchen-shape:hover {
    box-shadow: 0 2px 52px 0 rgba(0, 0, 0, 0.12);
  }
  .design-non-kitchen .kitchen-shape-title {
    color: #212121;
    
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 2.51px;
    line-height: 53px;
    text-align: center;
  }

  .design-non-kitchen .non-kitchen-shape-title {
    color: #212121;
    
    font-size: 18px;
    font-weight: 600;
    /* letter-spacing: 2.51px; */
    /* line-height: 53px; */
    text-align: center;
  }
  .design-non-kitchen .tick-div-class {
    position: absolute;
    right: 0;
    top: -12px;
  }
  
  .design-non-kitchen .tick-div-class-arabic {
    right: auto;
    left: 0;
  }
  .starRed {
    color: #ff0000;
  }
  @media (max-width: 1024px) {
    .design-non-kitchen {
      padding: 3% 15% 80px 15%;
    }
  }
  
  @media (max-width: 425px) {
    .design-non-kitchen {
      padding: 19% 7% 25px 7%;
    }
    .design-non-kitchen .sliver-value-div {
      margin-left: 17%;
    }
    .design-non-kitchen .sliver-value-divar {
      margin-left: 17%;
    }
    .design-non-kitchen .slider-div {
      margin-left: 24%;
    }
    .design-non-kitchen .slider-divar {
      margin-left: 24%;
    }
    .design-non-kitchen .kitchen-image-div {
      text-align: center;
      border: 1px solid #1f2128;
      border-radius: 8.33px;
      margin-top: 10%;
      padding: 30px 0 95px 0;
      margin-bottom: 8%;
    }
    .imgnew_resfix {
      padding: 0px !important;
    }
  }
  
  @media (min-width: 1280px) and (max-width: 1365px) {
    .design-non-kitchen {
      padding: 3% 20% 88px 20%;
    }
  }
  
 #book-consultation-wrap {
     width: 100%;
     float: left;
 }

 .bookModal {
     width: 85% !important;
     padding: 0px !important;
 }
 
 .confirmation-modal{
    width: 60% !important;
    padding: 0px !important;
 }

 .mandatory-error{
    width: 100%;
    padding-top: 10px;
    color: red;
    font-size: 15px;
    text-align: center;
 }
 .confirmation-modal .ant-modal-body {
    padding: 0px !important;
}

.confirmation-modal .ant-modal-body  {
    overflow: unset;
}
 .confirmation-modal .ant-modal-close {
     outline: none;
     border: none;
     position: absolute;
     top: 10px;
     right: 10px;
 }

 .lets-started {
     width: 65% !important;
     padding: 0px !important;
 }

 .lets-started .ant-modal-body {
    padding: 0px !important;
}

 .bookModal .ant-modal-body {
     padding: 0px !important;
     background-color: #F6F6F6;
 }
 .bookModal .ant-modal-body  {
    overflow: unset;
}
 .bookModal .ant-modal-close {
     outline: none;
     border: none;
     position: absolute;
     top: 10px;
     right: 10px;
 }

 .lets-both-btn {
    display: flex;
 }

.lets-both-btn-arabic {
    display: flex;
    direction: ltr;
}

 .lets-begin-button {
     width: 100%;
     float: left;
     margin-top: -200px;
     margin-left: 250px;
     /* margin-left: 250px; */
 }

 .lets-one-begin-button {
    width: 100%;
    float: left;
    margin-top: -200px;
    /* margin-left: 250px; */
    /* margin-left: 250px; */
}

 .lets-begin {
     height: 50px;
     width: 200px;
     background-color: #242E49;
     margin: auto;
     padding: 12px;
     color: #F6F6F6;
     
     font-size: 18px;
     cursor: pointer;
     font-weight: 500;
     letter-spacing: 0.6px;
     line-height: 22px;
     text-align: center;
 }

 .lets-begin-2-button {
    width: 100%;
     float: left;
     margin-top: -200px;
     margin-right: 200px;
 }

 .lets-begin-2 {
    height: 50px;
    width: 250px;
    background-color: #242E49;
    margin: auto;
    padding: 12px;
    color: #F6F6F6;
    
    font-size: 18px;
    cursor: pointer;
    font-weight: 500;
    letter-spacing: 0.6px;
    line-height: 22px;
    text-align: center;
}

 .header-title {
     color: #212121;
     font-size: 24px;
     letter-spacing: 0;
     line-height: 40px;
     text-align: center;
     text-transform: uppercase;
 }

 .header-description {
     color: #606060;
     font-size: 18px;
     font-weight: 500;
     letter-spacing: 0.6px;
     line-height: 22px;
     text-align: center;
 }

 .got-it-button {
     color: #F6F6F6;
     font-size: 18px;
     font-weight: 500;
     letter-spacing: 0.6px;
     line-height: 22px;
     text-align: center;
     padding: 15px;
     width: 200px;
     background-color: #242E49;
     margin: auto;
     margin-top: -9%;
     cursor: pointer;
 }

 .go-back-button {
     box-sizing: border-box;
     padding: 15px;
     width: 200px;
     border: 1px solid #242E49;
     color: #242E49;
     font-size: 18px;
     font-weight: 500;
     letter-spacing: 0.6px;
     line-height: 22px;
     text-align: center;
     background-color: #FFFFFF;
     cursor: pointer;
 }

 .continue-button {
     width: 200px;
     padding: 16px;
     background-color: #242E49;
     color: #F6F6F6;
     outline: none;
     border: none;
     font-size: 18px;
     font-weight: 500;
     letter-spacing: 0.6px;
     line-height: 22px;
     text-align: center;
     cursor: pointer;
 }

 .go-back-wrap {
     text-align: -webkit-right;
 }

 .button-wrap {
     margin-top: -90px;
     padding-bottom: 50px;
 }

 .continue-button-wrap {
     margin-top: 100px;
     text-align: center;
     width: 100%;
     padding-left: 38px;
 }

 .current-step {
     position: absolute;
     bottom: 118px;
     right: 48%;
     color: #606060;
     font-size: 18px;
     letter-spacing: 0;
     line-height: 22px;
 }

 .current-step-one {
     position: absolute;
     bottom: 118px;
     right: 47%;
     color: #606060;
     font-size: 18px;
     letter-spacing: 0;
     line-height: 22px;
 }


 @media (max-width: 1024px) {
     .button-wrap {
         margin-top: -45px;
     }
     .current-step-one {
        right: 46%;
    }
 }

 @media (max-width: 425px) {
     .go-back-wrap {
         text-align: -webkit-center
     }

     .continue-wrap {
         text-align: -webkit-center
     }

     .go-back-button {
         width: 140px;
         padding: 10px;
         margin-bottom: 25px;
     }

     .continue-button {
         width: 140px;
         padding: 10px;
     }


     .current-step {
         bottom: 185px !important;
         right: 42%;
     }

     .current-step-one {
         bottom: 73px !important;
         right: 41%;
     }

     .got-it-button {
         margin-top: -50%;
         padding: 15px;
     }
     .continue-button-wrap{
         padding-left:0px;
     }
 }

 @media (max-width: 600px){
    .lets-begin-button {
        margin-left: 0px;
    }
    .lets-begin-2-button {
         margin-top: -100px;
     }
     .lets-both-btn {
        display: block;
     }
     .lets-both-btn-arabic {
        display: block;
        direction: ltr;
    }
 }

 @media (min-width: 601px) and (max-width: 1024px){
    .lets-begin-button {
        margin-left: 15px !important; 
    }
    .lets-begin-2-button {
        margin-right: 20px;
    }
 }

.drawer-class-wrap .notf-order-id{
    color: #606060;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 25px;
}
.drawer-class-wrap .notf-order-idmes{
    color: #F1823B;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 25px;
}

.drawer-class-wrap .notf-contract-status{
    color: #212121;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 25px;
    text-transform: uppercase;
}

.drawer-class-wrap .notf-contract-description{
    /* color: #606060; */
    color:#F1823B;
    
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 25px;
}

.drawer-class-wrap .notification-tile{
    padding-bottom: 15px;
    padding-top: 15px;
    cursor: pointer;
}

/* .drawer-class-wrap .notification-tile:hover{
    background-color: #6060601f;
} */
.tile_grey{
    background-color: #6060601f;margin-top: 15px;
}
.drawer-class .ant-drawer-title{
    color: #343434;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.6px;
    line-height: 35px;
}

.drawer-classarabic  .ant-drawer-title{
    color: #343434;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.6px;
    line-height: 35px;
}
.drawer-classarabic .ant-drawer-close{
    left: 0 !important;
    right: auto;
}
.drawer-classarabic .ant-drawer-close-x{
    float: left;
}
.welcome-div{
    background: #fff;
    padding: 30px 40px;
}

.welcome-name{
    color: #212121;
    
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.8px;
    line-height: 29px;
    padding-bottom: 10px;
    text-transform:uppercase;
}

.welcome-text{
    color: #606060;
    
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.6px;
    line-height: 22px;
}

.input-heading{
    color: #404040;
    
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.6px;
    line-height: 22px;
}

.input-field-class{
    text-align: left;
    outline: none;
    width: 100%;
    padding-left: 15px;
    font-size: 16px;
    height: 50px;
    border: 1px solid #EEEEEE;
    margin-bottom: 2%;
    font-weight: 500;
    padding-right: 13%;
    margin-top: 2%;
}

.location_symbol_wrap{
    position: absolute;
    right: 2%;
    top:47%;
    text-align: center;
    line-height: 45px;
    height: 50px;
    width: 70px;
    border: 1px solid #EEEEEE;
    background-color: #242e4926;
}
.go-back-buttonarabic{
    box-sizing: border-box;
    padding: 15px;
    width: 200px;
    border: 1px solid #242E49;
    color: #242E49;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.6px;
    line-height: 22px;
    text-align: center;
    background-color: #FFFFFF;
    cursor: pointer;
    float:left;
}
.button-wrap-div{
    width: 100%;
    margin-top: 3%;
    padding-bottom: 0;
}

.error-div{
    color: red;
    font-size: 14px;
    width: 100%;
    float: left;
    text-align: left;
}
@media screen and (min-width: 768px) and (max-width: 991px){
    .editprf_resp{
        padding-top: 10px;
        text-align: center;
        padding-bottom: 7%;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%!important;
    }
    .welcome-text{
        width: 100%;
        text-align: justify;
    }
    .welcome-name{
        text-align: center;
    }
    .prfdet_resp{
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%!important;
    }
}

@media (min-width: 320px) and (max-width: 575px){
.update-details-modal{
    width: 90% !important;
}
.editprf_resp{
    padding-top: 10px;
    text-align: center;
    padding-bottom: 7%;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%!important;
}
.welcome-text{
    width: 100%;
    text-align: justify;
}
.welcome-name{
    text-align: center;
}
.prfdet_resp{
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%!important;
}
.welcome-div{
    padding: 30px 0px;
}
.input-field-class{
    text-align: left;
    outline: none;
    width: 100%;
    padding-left: 15px;
    font-size: 16px;
    height: 50px;
    border: 1px solid #EEEEEE;
    margin-bottom: 2%;
    font-weight: 500;
    padding-right: 28%;
    margin-top: 2%;
}
}
.mobile-heading{
    color: #404040;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.6px;
    line-height: 22px;
    width: 100%;
    float: left;
    margin-bottom: 6%;
}
.otp-button-wrap-div{
    width: 100%;
    margin-top: 3%;
    padding-bottom: 0;
}

.inputfield{
    width: 100%;
}
.tel_appnuii{
    text-align: left;
    width: 500px!important;
    float: left;
    border-radius: 0px!important;
    height: 48px!important;
    outline: none;
    border: 1px solid #6154540d!important;
    margin-bottom:5%;
}
.tel_appnuiiarabic{
    text-align: left;
    width: 500px!important;
    float: right;
    border-radius: 0px!important;
    height: 48px!important;
    outline: none;
    border: 1px solid #6154540d!important;
    margin-bottom:5%;
}
.fix_otp{
    text-align: left;
    padding-top: 15px;
    color: #606060;
    font-size: 16px;
    font-weight: 600;
}
.inputfield .form-control:focus{
    box-shadow: none;
    background-color: #fff;
}
.inputfield .selected-flag{
    border-right: 1px solid #61545436;
}

.cancel-button {
    box-sizing: border-box;
    padding: 12px;
    width: 225px;
    border: 1px solid #242E49;
    color: #242E49;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.6px;
    line-height: 22px;
    text-align: center;
    background-color: #FFFFFF;
    cursor: pointer;
}

.getotp-button {
    width: 225px;
    padding: 13px;
    background-color: #242E49;
    color: #F6F6F6;
    outline: none;
    border: none;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.6px;
    line-height: 22px;
    text-align: center;
    cursor: pointer;
}

.otp_text_field-change{
    width: 100%;
    float: left;
    text-align: center;
    margin-top: 2%;
}

.change_otp_fld_niu{
    width: 67px!important;
    height: 50px;
    border: 1px solid #EEEEEE;
    outline: none;
    text-align: center!important;
    font-size: 16px;
    font-weight: bold;
    margin-right:10px;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .change_otp_fld_niu{
        width: 40px!important; 
    }
    .cancel-button{
        width: 185px;
    }
    .getotp-button{
        width: 185px;
    }
}

@media (min-width: 320px) and (max-width: 575px){
    .change_otp_fld_niu{
        width: 48px!important;
    }
    .update-details-modal{
        width: 90% !important;
    }
    .editprf_resp{
        padding-top: 10px;
        text-align: center;
        padding-bottom: 7%;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%!important;
    }
    .welcome-text{
        width: 100%;
        text-align: justify;
    }
    .welcome-name{
        text-align: center;
    }
    .prfdet_resp{
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%!important;
    }
    .welcome-div{
        padding: 30px 0px;
    }
    .cancel-button{
        margin-bottom: 25px;
    }
    .tel_appnuii{
        width: 260px!important;
        font-size: 18px!important;
    }
}

#update-details-wrap {
    width: 100%;
    float: left;
}


.update-details-modal{
    width:60% !important;
}

.update-details-modal .ant-modal-body {
    background-color: #F6F6F6;
    padding: 5% 8%;
}

.update-details-modal .ant-modal-close {
    outline: none;
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
}
@media (min-width: 320px) and (max-width: 575px){
    .update-details-modal{
        width:90% !important;
    }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
    .update-details-modal{
        width:80% !important;
    }
}
.header-area {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 1000;
    height: 100px;
    background: #fff;
    box-shadow: 0 2px 24px 0 rgba(0,0,0,0.21);
  }
  
  .header-area .main-nav .logo {
    float: left;
    margin-top: 35px;
    transition: all 0.3s ease 0s;
  }

  .header-area .main-nav .logo-margin{
    margin-top: 21px !important;
  }
  
  .header-area .main-nav .logo img {
    transition: all 0.3s ease 0s;
  }
  
  .header-area .main-nav .nav {
    float: right;
    margin-top: 30px;
    margin-left: 0px;
    transition: all 0.3s ease 0s;
  }
  
  .header-area .main-nav .nav li {
    padding-left: 10px;
    padding-right: 20px;
  }
  
  /* .header-area .main-nav .nav li:last-child {
    padding-right: 0px;
  } */

.header-area .language-switch-div{
  padding: 7px 10px;
  border: 3px solid #FCCC6A;
  cursor: pointer;
  margin-top: -3px;
}

.header-area .language-switch-div:hover{
  color: #FCCC6A;
}

  .header-area .main-nav .nav li a {
    display: block;
    font-weight: 400;
    color: #212121;
    font-size: 16px;
    letter-spacing: 1.44px;
    transition: all 0.3s ease 0s;
    height: 40px;
    line-height: 40px;
    border: transparent;
    letter-spacing: 1.58px;
    padding: 0px 10px;
  }
  
  .header-area .main-nav .nav li a:hover {
    color: #212121;
  }
  
  .header-area .main-nav .nav li a.contact-btn {
    padding-left: 25px;
    padding-right: 25px;
    border: 1px solid #1F2128;
    height: 35px;
    margin-top: 2px;
    line-height: 34px;
    letter-spacing: 0.5px;
    background: none;
    color: #212121;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease 0s;
  }
  
  .header-area .main-nav .nav li a.contact-btn:hover {
    background: #1F2128;
    color: #fff;
  }
  
  .header-area .main-nav .nav li.submenu {
    position: relative;
    padding-right: 35px;
  }
  
  .header-area .main-nav .nav li.submenu:after {
    font-family: FontAwesome;
    content: "\F107";
    font-size: 12px;
    color: #212121;
    position: absolute;
    right: 32px;
    top: 12px;
  }
  
  .header-area .main-nav .nav li.profile-header:after {
    font-family: none;
    content: "";
    font-size: 12px;
    color: #212121;
    position: absolute;
    right: 32px;
    top: 12px;
  }

  .header-area .main-nav .nav li.profile-header ul {
    position: absolute;
    width: 200px !important;
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.08);
    overflow: hidden;
    top: 50px !important;
    right: -50px !important;
    opacity: 0;
    -webkit-transform: translateY(-2em);
            transform: translateY(-2em);
    visibility: hidden;
    z-index: -1;
    transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
  }

  .header-area .main-nav .nav li.submenu ul {
    position: absolute;
    width: 200px;
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.08);
    overflow: hidden;
    top: 40px;
    opacity: 0;
    -webkit-transform: translateY(-2em);
            transform: translateY(-2em);
    visibility: hidden;
    z-index: -1;
    transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
  }
  
  .header-area .main-nav .nav li.submenu ul li {
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .header-area .main-nav .nav li.submenu ul li a {
    display: block;
    background: #fff;
    color: #212121;
    padding-left: 20px;
    height: 40px;
    line-height: 40px;
    transition: all 0.3s ease 0s;
    position: relative;
    font-size: 13px;
  }
  

  .header-area .main-nav .nav li.submenu ul li a:before {
    content: '';
    position: absolute;
    width: 0px;
    height: 40px;
    left: 0px;
    top: 0px;
    bottom: 0px;
    transition: all 0.3s ease 0s;
    background: #1F2128;
  }
  
  .header-area .main-nav .nav li.submenu ul li a:hover {
    background: #fafafa;
    padding-left: 25px;
  }
  
  .header-area .main-nav .nav li.submenu ul li a:hover:before {
    width: 1px;
  }
  
  .header-area .main-nav .nav li.submenu:hover ul {
    visibility: visible;
    opacity: 1;
    z-index: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    transition-delay: 0s, 0s, 0.3s;
  }
  
  .header-area .main-nav .menu-trigger {
    cursor: pointer;
    display: block;
    position: absolute;
    top: 23px;
    width: 32px;
    height: 40px;
    text-indent: -9999em;
    z-index: 99;
    right: 40px;
    display: none;
  }
  
  .header-area .main-nav .menu-trigger span,
  .header-area .main-nav .menu-trigger span:before,
  .header-area .main-nav .menu-trigger span:after {
    transition: all 0.4s;
    background-color: #212121;
    display: block;
    position: absolute;
    width: 30px;
    height: 2px;
    left: 0;
  }
  
  .header-area .main-nav .menu-trigger span:before,
  .header-area .main-nav .menu-trigger span:after {
    transition: all 0.4s;
    background-color: #212121;
    display: block;
    position: absolute;
    width: 30px;
    height: 2px;
    left: 0;
    width: 75%;
  }
  
  .header-area .main-nav .menu-trigger span:before,
  .header-area .main-nav .menu-trigger span:after {
    content: "";
  }
  
  .header-area .main-nav .menu-trigger span {
    top: 16px;
  }
  
  .header-area .main-nav .menu-trigger span:before {
    -webkit-transform-origin: 33% 100%;
    transform-origin: 33% 100%;
    top: -10px;
    z-index: 10;
  }
  
  .header-area .main-nav .menu-trigger span:after {
    -webkit-transform-origin: 33% 0;
    transform-origin: 33% 0;
    top: 10px;
  }
  
  .header-area .main-nav .menu-trigger.active span,
  .header-area .main-nav .menu-trigger.active span:before,
  .header-area .main-nav .menu-trigger.active span:after {
    background-color: transparent;
    width: 100%;
  }
  
  .header-area .main-nav .menu-trigger.active span:before {
    -webkit-transform: translateY(6px) translateX(1px) rotate(45deg);
    transform: translateY(6px) translateX(1px) rotate(45deg);
    background-color: #212121;
  }
  
  .header-area .main-nav .menu-trigger.active span:after {
    -webkit-transform: translateY(-6px) translateX(1px) rotate(-45deg);
    transform: translateY(-6px) translateX(1px) rotate(-45deg);
    background-color: #212121;
  }

  .locat_headerapp{
    display: block;
    font-weight: 400;
    color: #212121;
    font-size: 13px;
    letter-spacing: 1.44px;
    transition: all 0.3s ease 0s;
    height: 40px;
    line-height: 40px;
    border: transparent;
    padding: 0px 10px;
    cursor: pointer;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .loc_innerwrap{
    float: left;
    margin-top: 30px;
    margin-left:4%;
    cursor: pointer;
    width: 29%;
  }
  .loc_innerwrapeng{
    float: left;
    margin-top: 30px;
    margin-left:4%;
    cursor: pointer;
    width: 29%;
  }
  .loc_innerwrapone{
    float: left;
    margin-right: -13px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    text-transform: capitalize;
    font-weight: bold;
    width: 20%;
    padding-bottom: 11px
  }
  .loc_innerwraptwo{
    float: left;
    color: #000000ad;
    width: auto;
    max-width: 70%;
  }
  .locat_headerappcap{
    display: block;
    font-weight: 600;
    color: #212121;
    font-size: 15px;
    transition: all 0.3s ease 0s;
    height: 40px;
    line-height: 40px;
    border: transparent;
    padding: 0px 10px;
    cursor: pointer;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .downapp_angle{
    color: orange;
    font-size: 18px;
    padding-top: 2px;
  }
  .font_angkechange{
    float: left;
    padding-top: 8px;
  }
  .pwd_fieldsinnerlocate{
    outline: none;
    width: 100%;
    padding-left: 15px;
    font-size: 16px;
    height: 50px;
    border: 1px solid #EEEEEE;
    margin-bottom: 3%;
    font-weight: 500;
    padding-right: 9%;
  }
  .lo_ero_map{
    text-align: center;font-weight: 600;padding-top:5%;
  }
  .lo_ero_mapwrap{
    text-align: center;padding-top:2%;width:100%;float:left;
  }
  .error_imagemap{
    text-align: center;
    padding-top: 6%;
  }
  .error_imagemapwrap{
    width: 215px;
    height: 215px;
  }
  @media (max-width: 1200px) {
    .header-area .main-nav .nav li {
      padding-left: 12px;
      padding-right: 12px;
    }
    .header-area .main-nav .nav li.submenu {
      padding-right: 20px;
    }
    .header-area .main-nav .nav li.submenu:after {
      right: 5px;
    }
  }
  
  @media (max-width: 991px) {
    .header-area {
      background: #fff;
      height: 125px;
      box-shadow: 0 2px 24px 0 rgba(0,0,0,0.21);
    }
    .loc_innerwrap{
      margin-top:0px;
      width:100%;
    }
    .loc_innerwrapeng{
      margin-top:10px;
      width:100%;
    }
    .ant-drawer-content-wrapper{
      width: 375px!important;
    }
    .header-area .container {
      padding: 0px;
    }
    .header-area .logo {
      margin-left: 30px;
      margin-top: 20px !important;
    }
    .header-area .menu-trigger {
      display: block !important;
    }
    .header-area .main-nav {
      overflow: hidden;
    }
    .header-area .main-nav .nav {
      float: left;
      width: 100%;
      margin-top: 20px !important;
      display: none;
      transition: all 0s ease 0s;
      margin-left: 0px;
    }
    .header-area .main-nav .nav li {
      width: 100%;
      background: #fff;
      border-bottom: 1px solid #f5f5f5;
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
    .header-area .main-nav .nav li a {
      height: 50px !important;
      line-height: 50px !important;
      padding: 0px !important;
      padding-left: 30px !important;
      border: none !important;
      background: #fff !important;
      color: #1F2128 !important;
    }
    .header-area .main-nav .nav li a:hover {
      background: #f5f5f5 !important;
    }
    .header-area .main-nav .nav li a.contact-btn {
      margin-top: 0px;
      border-radius: 0px;
    }
    .header-area .main-nav .nav li.submenu ul {
      position: relative;
      visibility: inherit;
      opacity: 1;
      z-index: 1;
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
      transition-delay: 0s, 0s, 0.3s;
      top: 0px;
      width: 100%;
      box-shadow: none;
      height: 0px;
    }
    .header-area .main-nav .nav li.submenu ul li a {
      padding-left: 50px !important;
    }
    .header-area .main-nav .nav li.submenu ul li a:hover:before {
      width: 0px;
    }
    .header-area .main-nav .nav li.submenu ul.active {
      height: auto !important;
    }
    .header-area .main-nav .nav li.submenu:after {
      color: #212121;
      right: 25px;
      font-size: 14px;
      top: 15px;
    }
    .header-area .main-nav .nav li.submenu:hover ul, .header-area .main-nav .nav li.submenu:focus ul {
      height: 0px;
    }
  }

  .header-area .notification-oval{
    position: absolute;
    right: 19px;
  }
  
  @media (min-width: 992px) {
    .header-area .main-nav .nav {
      display: flex !important;
    }
  }

  @media (max-width: 1024px) {
    .header-area .notification-oval{
      position: absolute;
      right: 10px;
    }
  }
  
/* NABVAR */
@font-face {
  font-family: "RBBold";
  src: local("RBBold"),
   url(/static/media/RB-Bold.210e4b89.ttf) format("truetype");
  font-weight: bold;
 }
 @font-face {
  font-family: "RBLight";
  src: local("RBLight"),
   url(/static/media/rb-light.6a3a1043.ttf) format("truetype");
  font-weight: bold;
 }
 @font-face {
  font-family: "RBRegular";
  src: local("RBRegular"),
   url(/static/media/RB-Regular.03b996d5.ttf) format("truetype");
  font-weight: bold;
 }
.niuli-navbar{
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 1000;
    background: #fff;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 21%); 
}

/* NAVBAR BOX*/

.niuli-navbar-box{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: relative;
    height: 100px;
    width: 94vw;
    margin: auto;
}
.niuli-navbar-box-english{
    direction: ltr;
}
.niuli-navbar-box-arabic{
        direction: rtl;
}

/* NAVBAR MAIN */
.niuli-navbar-main {
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    position: absolute;
  }

.niuli-navbar-main-mobile {
  top: 0;
  display: flex;
  flex-direction: column;
  -webkit-transform: translate(2%, 50%);
          transform: translate(2%, 50%);
}
.niuli-navbar-main-pc {
  display: flex;
  flex-direction: row;
}

.niuli-navbar-main-english{
  left: 0;
  text-align: left;
}

.niuli-navbar-main-arabic{
  right: 0;
  text-align: right;
}

/* NAVIGATION MENU */
.niuli-nav-menu {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 100vw;
  justify-content: end;
}

.niuli-nav-item {
  display: flex;
  align-items: center;
  height: 60px;
  }
.niuli-nav-links {
  display: block;
  font-weight: 400;
  color: #212121;
  font-size: 15px;
  letter-spacing: 1.44px;
  transition: all .3s ease 0s;
  height: 40px;
  line-height: 40px;
  border: transparent;
  letter-spacing: 1.58px;
  padding: 0 10px;
  }
  
.niuli-nav-links:hover {
  transition: all 0.2s ease-out;
  color: #212121;
  background-color: #eee;
  }

/* LOGIN BUTTON */
.niuli-login-btn{
  font-size: 17px;
  font-weight: 400;
  padding-left: 25px;
  padding-right: 25px;
  border: 1px solid #1f2128;
  outline: none;
  background: none;
  color: #212121;
  overflow: hidden;
  transition: all .3s ease 0s;
  padding-top: 2px;
  padding-bottom: 3px;
}
.niuli-login-btn:hover{
  background: #1f2128;
  color: #fff;
}

/* LANGUAGE SWITCHER */
.niuli-navbar-language-switch{
  padding: 0px 8px;
  border: 3px solid #fccc6a;
  cursor: pointer;
  color: #212121;
}
.niuli-navbar-language-switch:focus{
  color: #fccc6a;
}

.niuli-navbar-language-switch:hover{
  color:#fccc6a;
}

/* ICON BUTTONS */
.niuli-navbar-profile-icon{
  width:2rem;
}

/* CONTRAST COLOR */
.niuli-navbar-contrast-color{
  color: #fccc6a;
}

/* NAVBAR_END */
.niuli-navbar-end{
  position: absolute;
  display: flex;
  flex-direction: row;
  grid-template-columns: repeat(5, auto);
  list-style: none;
  text-align: center;
  justify-content: flex-end;
  align-items: center;
}
.niuli-navbar-end-english{
  right:0;
}
.niuli-navbar-end-arabic{
  left:0;
}
.niuli-navbar-end-pc{
  grid-gap: 20px;
}
.niuli-navbar-end-mobile{
  grid-gap: 16px;
}

/* MENU HAMBURGER ICON */
.niuli-navbar-menu-icon{
  color: #fccc6a;
  font-size: 1.6rem;
}
.niuli-navbar-menu-icon-mobile-div{
  width: 2rem;
}


/* DROPDOWN */
.niuli-dropdown-menu {
  width: 200px;
  position: absolute;
  top: 70px;
  list-style: none;
  text-align: start;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 21%);
}

.niuli-dropdown-mobile {
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 21%);
}
  
.niuli-dropdown-menu li {
  background: white;
  cursor: pointer;
  color: #212121;
}
.niuli-dropdown-menu li:hover {
  background: white;
}
.niuli-dropdown-menu.clicked {
  display: none;
}
  .niuli-dropdown-link {
    display: block;
    width: 100%;
    text-decoration: none;
    color: #212121;
    background: #fff;
    color: #212121;
    padding-left: 20px;
    height: 40px;
    line-height: 40px;
    transition: all .3s ease 0s;
    font-size: 13px;
  }

  .niuli-dropdown-link:hover {
    background: #fafafa ;
    padding-left: 25px ;
    border-left: 1px solid black ;
    color: #fccc6a;
  }
  .niuli-dropdown-li {
    display: block;
    width: 100%;
    text-decoration: none;
    color: #212121;
    background: #fff;
    color: #212121;
    height: 40px;
    line-height: 40px;
    transition: all .3s ease 0s;
    font-size: 15px;
  }
  .niuli-dropdown-li-english {
    padding-left: 20px;
  }
  .niuli-dropdown-li-english:hover {
    background: #fafafa ;
    padding-left: 25px ;
    border-left: 1px solid black ;
    color: #fccc6a;
  }
  .niuli-dropdown-li-arabic {
    padding-right: 20px;
  }
  .niuli-dropdown-li-arabic:hover {
    background: #fafafa ;
    padding-right: 25px ;
    border-right: 1px solid black ;
    color: #fccc6a;
  }

/* LOCATION */
.niuli-navbar-loc{
  display: inline-flex;
}
.niuli-navbar-loc-city{
  display: block;
  font-weight: 600;
  color: #212121;
  font-size: 15px;
  transition: all .3s ease 0s;
  height: 40px;
  line-height: 40px;
  border: transparent;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  overflow: hidden;
  white-space: nowrap;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  text-transform: capitalize;
}
.niuli-navbar-loc-city-pc{
  padding: 0 18px;
}
.niuli-navbar-loc-city-mobile{
  padding:0 0px
}
.niuli-navbar-loc-address{
  display: block;
  font-weight: 400;
  color: #212121;
  font-size: 13px;
  transition: all .3s ease 0s;
  height: 40px;
  line-height: 40px;
  border: transparent;
  padding: 0 10px;
  cursor: pointer;
  width: -webkit-min-content;
  width: min-content;
  white-space: nowrap;
  letter-spacing: 1.44px;
}
.niuli-navbar-loc-address-pc{
  max-width: 12rem;
  /* max-width: auto; */
  overflow: hidden;
}
.niuli-navbar-loc-address-mobile{
  max-width: 15rem;
  /* max-width: auto; */
  overflow: hidden;
}
.niuli-navbar-loc-change-arrow{
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.niuli-navbar-down-arrow{
  color: #fccc6a;
  font-size: 18px;
}
.niuli-navbar-dropdown-arrow-pc{
  color: #fccc6a;
  padding: 3px;
  font-size: 18px;
}
.niuli-navbar-dropdown-arrow-mobile{
  display: none;
}


/* EXTRA */
.niuli-nav-links-mobile {
  display: none;
}
  
.niuli-nav-display-none{
  display: none !important;
}

.link_nav_def{
  padding-left:5px;padding-right:5px;
}
/* .niuli-nav-links:active{
  border-bottom: 1px solid #000;
}
.niuli-nav-links:focus{
  border-bottom: 1px solid #000;
} */
/* ol, ul {
    list-style: none;
}
li {
    display: inline-block;
    padding: 20px 0 20px;
    vertical-align: middle;
}
.niuli-nav-item a:hover, a:focus, a:active {
    color: #999;
    text-decoration: none;
}
.niuli-nav-item a {
    text-decoration: none;
    transition: color 0.1s, background-color 0.1s;
}
.niuli-nav-item a {
    position: relative;
    display: block;
    padding: 16px 0;
    margin: 0 12px;
    letter-spacing: 1px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 900;
    text-transform: uppercase;
    transition: color 0.1s,background-color 0.1s,padding 0.2s ease-in;
    
}
.niuli-nav-item a::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 3px;
    left: 0;
    height: 3px;
    width: 100%;
    background-color: #000;
    transform-origin: right top;
    transform: scale(0, 1);
    transition: color 0.1s,transform 0.2s ease-out;
}
.niuli-nav-item a:active::before {
    background-color: #000;
}
.niuli-nav-item a:hover::before, a:focus::before {
    transform-origin: left top;
    transform: scale(1, 1);
} */
.fix_circleredDot{
  float: right;
  position: absolute;
}
.notcir{
  color: #FCCC6A;
    float: right;
    position: relative;
    left: -11px;
}
.notcirar{
  color: #FCCC6A;
    float: right;
    position: relative;
    right: -11px;
}
.notcirmob{
  color: #FCCC6A;
    float: right;
    position: relative;
    left: -12px;
    top: -8px;
}
.notcirarmob{
  color: #FCCC6A;
    float: right;
    position: relative;
    left:30px;
    top: -8px;
}
.sat_noti{
  padding-left: 0px;
  padding-right: 6px;
}
.sat_notiarb{
  padding-left: 6px;
  padding-right: 6px;
}
.sat_noti:hover{
  background-color: #fff;
}
.sat_notiarb:hover{
  background-color: #fff;
}
.chat_imagenot{
  width: 30px;
  height: 30px;
}
.hidemob{
  display: none;
}
.heade_Deskhide{
  display: none;
}
.cur_ap{
  cursor: pointer;
}
/* LESS THAN 960 */
@media screen and (max-width: 960px) {
  .niuli-navbar-main-english{
    top:0px;
    left: 0;
    text-align: left;
  }

    .niuli-nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      /* height: 90vh; */
      position: absolute;
      top: 97px;
      left: -100%;
      opacity: 1;
		transition: background 0.36s cubic-bezier(0.32, 0.08, 0.24, 1),
			height 0.56s cubic-bezier(0.32, 0.08, 0.24, 1);
    }
  
    .niuli-nav-menu.active {
      /* background: #fff; */
      background: #eee9e9;
      left: 0;
      opacity: 1;
		transition: background 0.36s cubic-bezier(0.32, 0.08, 0.24, 1),
			height 0.56s cubic-bezier(0.32, 0.08, 0.24, 1);
      z-index: 1;
    }
  
    .niuli-nav-links {
      text-align: center;
      padding: 0rem;
      width: 100%;
      display: table;
    }
    .niuli-nav-links:hover {
     background-color: #eee;
      border-radius: 0;
    }
    .niuli-menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      -webkit-transform: translate(-100%, 60%);
              transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
    .niuli-nav-links-mobile {
      display: block;
      text-align: center;
      padding: 0.5rem;
      margin: 1rem auto;
      border-radius: 4px;
      width: 80%;
      background: black;
      text-decoration: none;
      color: #fff;
      font-size: 1.5rem;
    }
    .niuli-nav-links-mobile:hover {
      background:#fccc6a;
      color: white;
      transition: 250ms;
    }
    .niuli-nav-optional-button {
      display: none;
    }
    .not-in-mobile-class{
      display:none;
    }
    .hidemob{
      display: block;
    }
    .hidedes{
      display:none;
    }
    .niuli-dropdown-menunew{
      width: 200px;
    position: absolute;
    list-style: none;
    text-align: start;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 21%);
    top: 280px;
    left:268px;
    }
    .niuli-dropdown-menunewqr{
      width: 200px;
    position: absolute;
    list-style: none;
    text-align: start;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 21%);
    top:375px;
    left:280px;
    }
    .head_new{
      padding-top:27px;
    }
    .fix_downarrw{
      padding-left:12px;
      padding-right: 12px;
    }
    .heade_Deskhide{
      display: block;
    }
  }
  @media (min-width: 320px) and (max-width: 480px){
    .niuli-dropdown-menunew{
      width: 200px;
    position: absolute;
    list-style: none;
    text-align: start;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 21%);
    top: 280px;
    left: 103px;
    }
    .niuli-dropdown-menunewqr{
      width: 200px;
    position: absolute;
    list-style: none;
    text-align: start;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 21%);
    top:373px;
    left: 103px;
    }
    .head_new{
      padding-top:27px;
    }
    .fix_downarrw{
      padding-left:12px;
      padding-right: 12px;
    }
    .niuli-nav-links:hover {
      background-color:#fff;
       border-radius: 0;
     }
     .heade_Deskhide{
      display: block;
    }
  } 
  
.font-face-gm {
  font-family: "RBBold" !important;
}

.font-face-gmlight {
  font-family: "RBLight" !important;
}

.font-face-gmregular {
  font-family: "RBRegular" !important;
}

body {
  background: #f5f5f5;
}

body[dir="rtl"] {
  direction: rtl;
  text-align: right;
}

.RightAlignArabic {
  text-align: right !important;
}

.float-right-arabic {
  float: right !important;
}

.float-left-arabic {
  float: left !important;
}

.rowremoveArabic {
  margin-left: 0px;
  margin-right: 0px;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* 
---------------------------------------------
reset
--------------------------------------------- 
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
div pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
figure,
header,
nav,
section,
article,
aside,
footer,
figcaption {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

.row:before {
  display: inline-block;
}

.row:after {
  display: inline-block;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix {
  display: inline-block;
}

.row:before {
  display: inline-block;
}

.row:after {
  display: inline-block;
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.nav>li>a {
  padding: 0;
}

header,
nav,
section,
article,
aside,
footer,
hgroup {
  display: block;
}

* {
  box-sizing: border-box;
}

html,
body {
  font-family: "RBRegular", sans-serif !important;
  font-weight: 400;
  background-color: #fff;
  font-size: 16px;
  -webkit-text-size-adjust:100%;
  -moz-text-size-adjust:100%;
       text-size-adjust:100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0px;
  margin-bottom: 0px;
}

ul {
  margin-bottom: 0px;
}

/* html,
body {
  background: #f5f5f5;
  color: #606060;
  font-family: "Montserrat", sans-serif;
} */

::selection {
  background: #1F2128;
  color: #fff;
}

::-moz-selection {
  background: #1F2128;
  color: #fff;
}

.section {
  padding-top: 110px;
  padding-bottom: 110px;
}

.section.white {
  background: #fff;
}

.left-heading .section-title {
  color: #212121;
  font-size: 28px;
  letter-spacing: 3.91px;
  line-height: 34px;
  font-weight: 500;
  margin-bottom: 30px;
}

.center-heading {
  text-align: center;
}

.center-heading .section-title {
  font-weight: 400;
  font-size: 28px;
  color: #1F2128;
  letter-spacing: 3.13px;
  line-height: 38px;
  margin-bottom: 20px;
}

.text_upper {
  text-transform: capitalize;
}

.center-text {
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  color: #606060;
  line-height: 28px;
  letter-spacing: 1px;
  margin-bottom: 60px;
}

.center-text p {
  margin-bottom: 30px;
}

.left-text {
  font-weight: 400;
  color: #606060;
  font-size: 16px;
  letter-spacing: 1.6px;
  line-height: 28px;
}

.left-text p {
  margin-bottom: 30px;
}

.left-text p.dark {
  color: #1F2128;
}

.left-text ul li {
  color: #606060;
  font-size: 16px;
  letter-spacing: 1.6px;
  line-height: 19px;
  font-weight: 400;
  padding-left: 30px;
  position: relative;
  margin-bottom: 10px;
  transition: all 0.3s ease 0s;
}

/* .left-text ul li:before {
  font-family: FontAwesome;
  content: "\f178";
  left: 0px;
  position: absolute;
} */

.left-text ul li:hover {
  padding-left: 40px;
}

.align-self-center {
  align-self: center !important;
}

.align-self-bottom {
  align-self: flex-end !important;
}

.dark-btn {
  display: block;
  width: 195px;
  height: 45px;
  position: relative;
  overflow: hidden;
  border: 1px solid #1F2128;
}

.dark-btn .show-btn {
  position: absolute;
  top: 0px;
  cursor: pointer;
  left: 0px;
  width: 195px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  transition: all 0.3s ease 0s;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 1.35px;
  color: #1F2128;
}

.dark-btn .hide-btn {
  position: absolute;
  top: 0px;
  cursor: pointer;
  left: 195px;
  width: 195px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  transition: all 0.3s ease 0s;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 1.35px;
  color: #fff;
  background: #1F2128;
}

.dark-btn:hover .show-btn {
  left: -195px;
}

.dark-btn:hover .hide-btn {
  left: 0px;
}

.light-btn {
  display: block;
  width: 195px;
  height: 45px;
  position: relative;
  overflow: hidden;
  border: 1px solid #fff;
}

.light-btn .show-btn {
  position: absolute;
  top: 0px;
  cursor: pointer;
  left: 0px;
  width: 195px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  transition: all 0.3s ease 0s;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 1.35px;
  color: #fff;
}

.light-btn .hide-btn {
  position: absolute;
  top: 0px;
  cursor: pointer;
  left: 195px;
  width: 195px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  transition: all 0.3s ease 0s;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 1.35px;
  color: #1F2128;
  background: #fff;
}

.light-btn:hover .show-btn {
  left: -195px;
}

.light-btn:hover .hide-btn {
  left: 0px;
}

blockquote {
  margin-bottom: 28px;
  padding: 30px;
  padding-top: 40px;
  background: #fff;
  overflow: hidden;
}

blockquote .text {
  position: relative;
  text-align: center;
}

blockquote .text p {
  position: relative;
  width: 100%;
  z-index: 2;
  margin-bottom: 10px !important;
}

blockquote .text:before {
  font-family: FontAwesome;
  content: "\F10D";
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
  font-size: 40px;
  color: #e1ecf4;
}

blockquote .text:after {
  font-family: FontAwesome;
  content: "\F10E";
  position: absolute;
  right: 0px;
  bottom: 0px;
  z-index: 1;
  font-size: 40px;
  color: #e1ecf4;
}

blockquote .author {
  color: #1F2128;
  text-align: center;
}

.page-gallery {
  height: 185px;
  display: block;
  overflow: hidden;
  margin-bottom: 30px;
}

.loader_center {
  width: 100%;
  text-align: center;
  left: 21%;
  top: 52%;
  margin-bottom: 0px;
}

.margin-bottom-0 {
  margin-bottom: 0px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-bottom-30 {
  margin-bottom: 30px !important;
}

.margin-bottom-40 {
  margin-bottom: 40px !important;
}

.margin-bottom-50 {
  margin-bottom: 50px !important;
}

.margin-bottom-60 {
  margin-bottom: 60px !important;
}

.margin-bottom-90 {
  margin-bottom: 90px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-top-30 {
  margin-top: 30px !important;
}

.margin-top-40 {
  margin-top: 40px !important;
}

.margin-top-50 {
  margin-top: 50px !important;
}

.margin-top-60 {
  margin-top: 60px !important;
}

.padding-top-0 {
  padding-top: 0px !important;
}

.padding-top-70 {
  padding-top: 70px !important;
}

.padding-bottom-120 {
  padding-bottom: 100px !important;
}

.padding-bottom-140 {
  padding-bottom: 140px !important;
}

.brand_colorred {
  color: #be325e !important;
}

.brand_coloryellow {
  color: #e5ab49 !important;
}

.brand_colorpurple {
  color: #202945 !important;
}

.brand_colorblue {
  color: #009ba2 !important;
}

.brand_colorbrown {
  color: #530644 !important;
}

.section_font {
  font-size: 30px !important;
}

.remv_space {
  letter-spacing: 0px !important;
}

@media (max-width: 991px) {

  html,
  body {
    overflow-x: hidden;
  }

  .mobile-bottom-fix {
    margin-bottom: 30px;
  }

  .page-gallery {
    height: 140px;
  }
}
