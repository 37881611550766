.ready-to-meet-wrap {
    margin: 50px 0;
    padding: 5% 15% 50px 15%;
}

.ready-to-meet-padding{
padding: 5% 15% 50px 15%;
}

.ready-to-meet-with-error-wrap {
    padding: 5% 15% 20px 15%;
}


.ready-to-meet-wrap .meet-info-class {
    color: #212121;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.6px;
    text-transform: uppercase;
}

.ready-to-meet-wrap .location-alert{
    width: 100%;
    float: left;
    line-height: 0px;
    color: #ff2f00;
}

.ready-to-meet-wrap .button-wrap{
    margin-top: 30px;
    padding-bottom: 0px;
}

/* .ready-to-meet-wrap .meet-info-class::before {
    content: url(../../images/back-icon.png);
    position: absolute;
    left: -14px;
} */

.ready-to-meet-wrap .location-meet-up {
    width: 100%;
    float: left;
    padding-bottom: 1rem;
    /* line-height: 50px; */
}

.calender-class .react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    border-radius: 0;
    background-color: #fff;
    color: #212121;
}

.calender-class .react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
    background-color: #f0f0f0;
}

.calender-class .react-datepicker__header {
    background-color: #fff;
    border-bottom: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.calender-class,
.react-datepicker {
    background-color: #fff;
    border: none;
    color: #212121;
    
    font-size: 15px;
    letter-spacing: 2.09px;
    line-height: 18px;
    box-shadow: 0 2px 64px 0 rgba(0, 0, 0, 0.12);
    margin-top: 5%;
}

.calender-class .react-datepicker__current-month {
    color: #212121;
    
    font-size: 18px;
    letter-spacing: 2.51px;
    line-height: 70px;
}

.calender-class .react-datepicker__day-name {
    color: #606060 !important;
    
    font-size: 15px;
    letter-spacing: 2.09px;
    line-height: 18px;
}

.calender-class .react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: #212121;
    width: 40px;
    margin: 12px 20px;
}

.calender-class .react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
    color: #ccc !important;
}

.calender-class .react-datepicker__day--selected {
    background-color: #FFCF6C;
}


.calender-class .react-datepicker__navigation--next {
    background: url(../../images/next.png) no-repeat;
    width: 20px;
    height: 30px;
    border: none;
    right: 35px;
}

.calender-class .react-datepicker__navigation--previous {
    background: url(../../images/previous.png) no-repeat;
    width: 20px;
    height: 30px;
    border: none;
    left: 35px;
}

.calender-class .react-datepicker__navigation {
    top: 35px;
}

button:focus {
    border: none;
    outline: none;
}

.ready-to-meet-wrap .meet-time {
    width: 100%;
    float: left;
    text-align: center;
    margin-top: 50px;
}

.ready-to-meet-wrap .meet-prio {
    width: 100%;
    float: left;
    text-align: center;
}

.ready-to-meet-wrap .meet-time button {
    background-color: #FFFFFF;
    box-shadow: 0 2px 64px 0 rgba(0, 0, 0, 0.12);
    color: #202020;
    
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.4px;
    line-height: 17px;
    padding: 10px 20px;
    margin: 10px 15px;
    float: left;
    cursor: pointer;
    border: none;
}

.ready-to-meet-wrap .meet-prio div {
    background-color: #FFFFFF;
    box-shadow: 0 2px 64px 0 rgba(0, 0, 0, 0.12);
    color: #202020;
    
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.4px;
    line-height: 17px;
    padding: 10px 33px;
    margin: 10px 20px 10px 3px;
    float: left;
    cursor: pointer;
}



.ready-to-meet-wrap .date-time-wrap {
    width: 100%;
    float: left;
    padding-bottom: 30px;
}

.ready-to-meet-wrap .time-class:hover{
    background: rgb(255 207 108 / 52%);
}

.ready-to-meet-wrap .location-search {
    float: left;
    outline: none;
    width: 100%;
    padding-left: 15px;
    padding-right: 90px;
    font-size: 16px;
    height: 50px;
    border: 1px solid #EEEEEE;
    margin-bottom: 3%;
    font-weight: 500;
    margin-top: 3%;
}

.ready-to-meet-wrap .location-searchar {
    float: left;
    outline: none;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 16px;
    height: 50px;
    border: 1px solid #EEEEEE;
    margin-bottom: 3%;
    font-weight: 500;
    margin-top: 3%;
}

.ready-to-meet-wrap .refernce-code{
    float: left;
    outline: none;
    width: 74%;
    padding-left: 15px;
    font-size: 16px;
    height: 50px;
    border: 1px solid #EEEEEE;
    margin-bottom: 3%;
    font-weight: 500;
}

.ready-to-meet-wrap .location-symbol {
    position: absolute;
    right: 0%;
    top: 22%;
    text-align: center;
    line-height: 45px;
    height: 50px;
    width: 70px;
    border: 1px solid #EEEEEE;
    background-color: #242e4926;
}

.ready-to-meet-wrap .you-chose-card-info-screen {
    background-color: #FFFFFF;
    box-shadow: 0 2px 64px 0 rgba(0, 0, 0, 0.12);
    padding: 20px;
    margin-top: 20px;
}

.ready-to-meet-wrap .you-chose-title {
    color: #212121;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.6px;
    line-height: 19px;
    text-transform: uppercase;
    padding-bottom: 25px;
}

.ready-to-meet-wrap .you-chose-edit {
    float: right;
    color: blue;
    cursor: pointer;
}

.ready-to-meet-wrap .chose-info {
    color: #606060;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.53px;
    line-height: 19px;
    padding-left: 30px;
}

.ready-to-meet-wrap .chose-info-arabic{
    padding-right: 30px;
    padding-left: 0px !important;
}

.chose-info li {
    padding-bottom: 20px;
}

.chose-info li:hover {
    margin-left: 15px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

/* .chose-info li::before {
    content: url(../../images/back-icon.png);
    position: absolute;
    left: 39px;
} */

@media (max-width: 1024px) {
    .ready-to-meet-wrap {
        padding: 5% 10%;
    }

    .calender-class .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        margin: 10px 10px;
    }
}

@media (max-width: 425px) {
    .ready-to-meet-wrap {
        padding: 18% 5%;
    }
    .calender-class .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        margin: 10px 0px;
    }
    .ready-to-meet-wrap .meet-info-class{
        padding-left: 25px;
    }
    /* .ready-to-meet-wrap .meet-info-class::before{
        left: 18px;
    } */
    .ready-to-meet-wrap .location-symbol{
        right: 0%;
    }
    .ready-to-meet-wrap .location-search{
        width: 100%;
    }
    .ready-to-meet-wrap .location-searchar{
        width: 100%;
    }

    .ready-to-meet-wrap .refernce-code{
        width: 100%;
    }
}

@media (min-width: 1280px) and (max-width: 1365px){
    .calender-class .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        width: 30px;
        margin: 10px 15px;
    }
}