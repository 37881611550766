.consultation-confirmation{
    padding: 5% 0 126px 0;
    background-color: #F6F6F6;
}

.consultation-confirmation .timer-div{
    width: 274px;
    padding: 2%;
    text-align: center;
    margin: 6% auto 1% auto;
    background-color: #FFFFFF;
    box-shadow: 0 2px 64px 0 rgba(0,0,0,0.12);
    color: #242E49;
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 20px;
    text-align: center;
}

.thank-you-tick-class{
    width: 100%;
    float: left;
    text-align: center;
    padding: 20px;
}

.thank-you{
    width: 70px;
    height: 70px;
}

.rai-wave{
    animation-name: rai-sentry-pulse !important;
    animation-duration: inherit !important;
    animation-timing-function: linear !important;
    animation-iteration-count: infinite !important;
}


@media (max-width: 425px) {
    .consultation-confirmation{
        padding: 19% 15px 170px 15px;
        background-color: #F6F6F6;
    }
    .consultation-confirmation .timer-div{
        padding: 15px;
        width: 200px;
        float: left;
    }
}