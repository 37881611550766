.draft_wrapinnerdes{
    color: #606060;
    font-size: 18px;
    text-align: center;
    width: 100%;
    float: left;
    padding-top: 7px;
}
.draft_wrapitime{
    width: 100%;
    float: left;
    text-align: center;
    color: #2B2B2B;
    font-size: 17px;
    font-weight: 600;
    padding-bottom: 6%;
}
.designer_nameappmeet{
    color: #606060;
    font-size: 15px;
    width: 100%;
    float: left;
    padding-top: 10%;
    padding-bottom: 5%;
    font-weight: 500;
}
.designer_namecall{
    color: #606060;
    font-size: 15px;
    width: 100%;
    float: left;
    padding-top: 5%;
    font-weight: 500;
    margin-bottom: 7%;
}
.call_phoneno{
    padding-right: 13px;
}
.call_phonenoar{
    padding-left: 13px;
}
.meetdes_app{
    margin-bottom: 5%;
}
.designer_modalch .app-image-gallery .image-gallery-slide .image-gallery-image{
    width: 100%;
    object-fit: cover;
    max-width: 100%;
}
.app_std{
    width:100%;float:left;
}

.mopitstop{
    width:85%!important;
}
.mopitstop .ant-modal-body{
    padding:0px;
}

.remvapp_padng{
    padding:0px;
}
.mopitstop .ant-modal-close{
    outline: none;
    border: none;
}

.inti_Scrennap{
    color:#212121;
    width:100%;float:left;text-align: center;
}

.draft_wrapinner{
    color: #606060;
    font-size: 18px;
    text-align: center;
    width: 100%;
    float: left;
    padding-top: 7px;
    padding-bottom:5%;
}
.mopitstop .ant-modal-close-x{
    color: #000;
    font-weight: 600;
    font-size: 30px;
}

.app_designleft{
    width:100%;float:left;padding:20px;
    box-shadow: 1px 3px 12px -2px rgba(0,0,0,0.35);
    background-image:linear-gradient(#ffd8ba29,#ffffff);
    text-align: center;
}

.draft_wrapdesign_img{
    padding: 4% 15% 5% 15%;
    width: 100%;
    float: left;
    background: #F6F6F6;
}
.designer_nameinner{
    color: #212121;
    font-size: 17px;
    font-weight: bold;
    float: left;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 6px;
    text-transform: capitalize;
}
.designer_nameappexp{
    color: #212121;
    font-size: 15px;
    width: 100%;
    float: left;
}
.designer_nameapptxt{
    color: #606060;
    font-size: 15px;
    width: 100%;
    float: left;
    padding-top: 10%;
    padding-bottom: 15%;font-weight: 500;
}
.starsec{
    padding-left: 13px;
}
.starsecar{
    padding-right: 13px;
}
.star_inner{
    padding-right: 8px;
    margin-top: -5px;
}
.star_innerar{
    padding-left: 8px;
    margin-top: -5px;
}
.designer-profile-image{
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

.video_secplay{
    position: absolute;
    top: 130px;
    right: 128px;
}
.box_applfix{
    right: 27px;
    position: absolute;
    bottom: 54px;
    float: left;
}
.app_stdrate_prof{
    width:100%;float:left;background: #fff;padding:20px;margin-top: 4%;
    margin-bottom: 3%;
}
.rate_theprof{
    color: #313131;
    float: left;
    width: 100%;
    font-size: 15px;
    font-weight: 500;
}
.prf_nameratg{
    float: right;
    color: #606060;
    font-size: 15px;
    font-weight: 500;
    padding-top: 15px;
}
.prf_nameratgstar{
    float: left;
    color: #606060;
    font-size: 15px;
    font-weight: 500;
    padding-top: 8px;
}
.dark-btnorderatgdsgn {
    display: block;
    width:285px;
    height: 45px;
    position: relative;
    overflow: hidden;
    border: 1px solid #242E49;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 20px;
  }
  
  .dark-btnorderatgdsgn .show-btnorderatgdsgn {
    position: absolute;
    top: 0px;
    cursor: pointer;
    left: 0px;
    width: 285px;
    height: 45px;
    line-height: 42px;
    text-align: center;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 1.35px;
    color: #242E49;
  }
  
  .dark-btnorderatgdsgn .hide-btnorderatgdsgn {
    position: absolute;
    top: 0px;
    cursor: pointer;
    left: 285px;
    width: 285px;
    height: 45px;
    line-height: 42px;
    text-align: center;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 1.35px;
    color: #fff;
    background: #242E49;
  }
  
  .dark-btnorderatgdsgn:hover .show-btnorderatgdsgn {
    left: -195px;
  }
  
  .dark-btnorderatgdsgn:hover .hide-btnorderatgdsgn {
    left: 0px;
  }
  .tel_appokj{
    color: #606060;
    font-size: 15px;
    font-weight: 500;
  }
  .tel_appokj:hover{
    color: #606060;
    font-size: 15px;
    font-weight: 500;
  }
  @media (min-width: 320px) and (max-width: 480px){
    .draft_wrapdesign_img{ 
        padding: 19% 3% 15% 3%;
    }
.app_designleft{
    margin-bottom: 10%;
    margin-top: 5%;
    }
    .dark-btnorderatgdsgn{
        margin-bottom: 20px;
    }
    .video_secplay{
        top: 235px;
    }
    .box_applfix{
        display:none;
    }
  }
  @media (min-width: 320px) and (max-width: 575px){
    .draft_wrapdesign_img{ 
        padding: 19% 3% 15% 3%;
    }
    .app_designleft{
        margin-bottom: 10%;
        margin-top: 5%;
    }
    .dark-btnorderatgdsgn{
        margin-bottom: 20px;
    }
    .video_secplay{
        top: 235px;
    }
    .box_applfix{
        display:none;
    }
}
  @media screen and (min-width: 768px) and (max-width: 991px) {
    .draft_wrapdesign_img{
        padding: 8% 0% 8% 0%;
    }
  }