.long-arrow-left-header-location{
    right: -25px;
    position: absolute;
    top:6px;
}

.ready-to-meet-wrap .meet-info-class {
    color: #212121;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.6px;
    text-transform: uppercase;
}

.ready-to-meet-wrap .you-chose-edit {
    float: right;
    color: blue;
    cursor: pointer;
}