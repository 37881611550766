.choose-kitchen-wrap{
    background: #F6F6F6;
    margin: 3% 0;
}

.choose-kitchen-wrap-padding{
    padding: 5% 10% 51px 10%;
}

.choose-kitchen-wrap-with-error-padding{
    padding: 5% 10% 23px 10%;
}

.choose-kitchen-wrap .tick-div-class{
    position: absolute;
    right: 0;
    top: 17px;
    z-index: 99;
}

.choose-kitchen-wrap .tick-div-class-arabic{
    right: auto;
    left: 0;
}

.choose-kitchen-wrap .project-list-item {
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    display: block;
    margin-bottom: 30px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.choose-kitchen-wrap .button-wrap{
    margin-top: 60px;
    padding-bottom: 0px;
    width: 100%;
}

@media (max-width: 425px) {
    .choose-kitchen-wrap{
        padding: 20% 5% 60px 5%;
    }
    .resp_gobkbtn{
        float: none !important;
    }
}
