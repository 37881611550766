 #book-consultation-wrap {
     width: 100%;
     float: left;
 }

 .bookModal {
     width: 85% !important;
     padding: 0px !important;
 }
 
 .confirmation-modal{
    width: 60% !important;
    padding: 0px !important;
 }

 .mandatory-error{
    width: 100%;
    padding-top: 10px;
    color: red;
    font-size: 15px;
    text-align: center;
 }
 .confirmation-modal .ant-modal-body {
    padding: 0px !important;
}

.confirmation-modal .ant-modal-body  {
    overflow: unset;
}
 .confirmation-modal .ant-modal-close {
     outline: none;
     border: none;
     position: absolute;
     top: 10px;
     right: 10px;
 }

 .lets-started {
     width: 65% !important;
     padding: 0px !important;
 }

 .lets-started .ant-modal-body {
    padding: 0px !important;
}

 .bookModal .ant-modal-body {
     padding: 0px !important;
     background-color: #F6F6F6;
 }
 .bookModal .ant-modal-body  {
    overflow: unset;
}
 .bookModal .ant-modal-close {
     outline: none;
     border: none;
     position: absolute;
     top: 10px;
     right: 10px;
 }

 .lets-both-btn {
    display: flex;
 }

.lets-both-btn-arabic {
    display: flex;
    direction: ltr;
}

 .lets-begin-button {
     width: 100%;
     float: left;
     margin-top: -200px;
     margin-left: 250px;
     /* margin-left: 250px; */
 }

 .lets-one-begin-button {
    width: 100%;
    float: left;
    margin-top: -200px;
    /* margin-left: 250px; */
    /* margin-left: 250px; */
}

 .lets-begin {
     height: 50px;
     width: 200px;
     background-color: #242E49;
     margin: auto;
     padding: 12px;
     color: #F6F6F6;
     
     font-size: 18px;
     cursor: pointer;
     font-weight: 500;
     letter-spacing: 0.6px;
     line-height: 22px;
     text-align: center;
 }

 .lets-begin-2-button {
    width: 100%;
     float: left;
     margin-top: -200px;
     margin-right: 200px;
 }

 .lets-begin-2 {
    height: 50px;
    width: 250px;
    background-color: #242E49;
    margin: auto;
    padding: 12px;
    color: #F6F6F6;
    
    font-size: 18px;
    cursor: pointer;
    font-weight: 500;
    letter-spacing: 0.6px;
    line-height: 22px;
    text-align: center;
}

 .header-title {
     color: #212121;
     font-size: 24px;
     letter-spacing: 0;
     line-height: 40px;
     text-align: center;
     text-transform: uppercase;
 }

 .header-description {
     color: #606060;
     font-size: 18px;
     font-weight: 500;
     letter-spacing: 0.6px;
     line-height: 22px;
     text-align: center;
 }

 .got-it-button {
     color: #F6F6F6;
     font-size: 18px;
     font-weight: 500;
     letter-spacing: 0.6px;
     line-height: 22px;
     text-align: center;
     padding: 15px;
     width: 200px;
     background-color: #242E49;
     margin: auto;
     margin-top: -9%;
     cursor: pointer;
 }

 .go-back-button {
     box-sizing: border-box;
     padding: 15px;
     width: 200px;
     border: 1px solid #242E49;
     color: #242E49;
     font-size: 18px;
     font-weight: 500;
     letter-spacing: 0.6px;
     line-height: 22px;
     text-align: center;
     background-color: #FFFFFF;
     cursor: pointer;
 }

 .continue-button {
     width: 200px;
     padding: 16px;
     background-color: #242E49;
     color: #F6F6F6;
     outline: none;
     border: none;
     font-size: 18px;
     font-weight: 500;
     letter-spacing: 0.6px;
     line-height: 22px;
     text-align: center;
     cursor: pointer;
 }

 .go-back-wrap {
     text-align: -webkit-right;
 }

 .button-wrap {
     margin-top: -90px;
     padding-bottom: 50px;
 }

 .continue-button-wrap {
     margin-top: 100px;
     text-align: center;
     width: 100%;
     padding-left: 38px;
 }

 .current-step {
     position: absolute;
     bottom: 118px;
     right: 48%;
     color: #606060;
     font-size: 18px;
     letter-spacing: 0;
     line-height: 22px;
 }

 .current-step-one {
     position: absolute;
     bottom: 118px;
     right: 47%;
     color: #606060;
     font-size: 18px;
     letter-spacing: 0;
     line-height: 22px;
 }


 @media (max-width: 1024px) {
     .button-wrap {
         margin-top: -45px;
     }
     .current-step-one {
        right: 46%;
    }
 }

 @media (max-width: 425px) {
     .go-back-wrap {
         text-align: -webkit-center
     }

     .continue-wrap {
         text-align: -webkit-center
     }

     .go-back-button {
         width: 140px;
         padding: 10px;
         margin-bottom: 25px;
     }

     .continue-button {
         width: 140px;
         padding: 10px;
     }


     .current-step {
         bottom: 185px !important;
         right: 42%;
     }

     .current-step-one {
         bottom: 73px !important;
         right: 41%;
     }

     .got-it-button {
         margin-top: -50%;
         padding: 15px;
     }
     .continue-button-wrap{
         padding-left:0px;
     }
 }

 @media (max-width: 600px){
    .lets-begin-button {
        margin-left: 0px;
    }
    .lets-begin-2-button {
         margin-top: -100px;
     }
     .lets-both-btn {
        display: block;
     }
     .lets-both-btn-arabic {
        display: block;
        direction: ltr;
    }
 }

 @media (min-width: 601px) and (max-width: 1024px){
    .lets-begin-button {
        margin-left: 15px !important; 
    }
    .lets-begin-2-button {
        margin-right: 20px;
    }
 }