.more-infornmation-wrap {
  padding: 5% 15% 0% 15%;
}

.more-infornmation-wrap .button-wrap {
  margin-top: 80px;
  width: 100%;
}

.button-wrap-padding {
  padding-bottom: 50px;
}

.button-wrap-padding-with-error {
  padding-bottom: 18px !important;
}

.more-infornmation-wrap .site-info-class {
  color: #212121;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  position: relative;
}

.list-padding-arabic {
  padding-left: 0px;
  padding-right: 30px;
}

.list-padding-arabic:hover {
  padding-right: 40px;
}

.long-arrow-class-more-info {
  left: 31px;
  position: absolute;
}

.long-arrow-header {
  left: -25px;
  position: absolute;
  top: 5px;
}

.long-arrow-left-class-more-info {
  right: 42px;
  position: absolute;
}

.long-arrow-left-header {
  right: -25px;
  position: absolute;
  top: 5px;
}
.new-site .ant-radio-inner {
  width: 24px;
  height: 24px;
  border-color: #1f2128;
  background-color: #f6f6f6;
  top: 2px;
}

.new-site .ant-radio-inner::after {
  width: 16px;
  height: 16px;
  background-color: #1f2128;
}

.old-site .ant-radio-inner {
  width: 24px;
  height: 24px;
  border-color: #1f2128;
  top: 2px;
  background-color: #f6f6f6;
}

.old-site .ant-radio-inner::after {
  width: 16px;
  height: 16px;
  background-color: #1f2128;
}

.old-site,
.ant-radio-wrapper {
  color: #212121;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.6px;
  line-height: 19px;
}

.new-site,
.ant-radio-wrapper {
  color: #212121;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.6px;
  line-height: 19px;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #1f2128 !important;
}

.budget-select {
  border: 1px solid #242e49 !important;
  border-right-width: 2px solid #242e49 !important;
  background-color: #ffffff;
}

.budget-select,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border: none;
  border-right-width: 2px solid #242e49 !important;
}

.budget-select,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
  border-right-width: 2px solid #242e49 !important;
}

.budget-select,
.ant-select-focused.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border: none;
  border-right-width: 2px solid #242e49 !important;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.budget-select .ant-select-selector .ant-select-selection-placeholder {
  color: #606060;
  
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.6px;
  opacity: 1;
}

.more-infornmation-wrap .slider-div {
  width: 62%;
  float: left;
}

.more-infornmation-wrap .kitchen-slider .ant-slider-handle {
  margin-top: -6px;
  background-color: #242e49;
  border: solid 2px #ffffff;
  width: 22px;
  height: 22px;
}

.more-infornmation-wrap .kitchen-slider .ant-slider-step {
  background-color: #242e49;
  border-radius: 5px;
  height: 10px;
}

.more-infornmation-wrap .kitchen-slider .ant-slider {
  height: 10px !important;
  border-radius: 5px !important;
  background-color: #242e49 !important;
}

.more-infornmation-wrap .sliver-value-div {
  color: #3d3c3c;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  float: left;
  right: 16%;
  bottom: 28px;
  padding-top: 10px;
  padding-left: 13px;
}
.more-infornmation-wrap .sliver-value-divar {
  color: #3d3c3c;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  float: left;
  right: 16%;
  bottom: 28px;
  padding-top: 10px;
  padding-right: 13px;
}

.more-infornmation-wrap .you-chose-card-info-screen {
  background-color: #ffffff;
  box-shadow: 0 2px 64px 0 rgba(0, 0, 0, 0.12);
  padding: 20px;
  margin-top: 20px;
}

.more-infornmation-wrap .you-chose-title {
  color: #212121;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.6px;
  line-height: 19px;
  text-transform: uppercase;
  padding-bottom: 25px;
}

.more-infornmation-wrap .chose-info {
  color: #606060;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.53px;
  line-height: 19px;
  padding-left: 30px;
}

.more-infornmation-wrap .chose-info-arabic {
  padding-right: 30px;
  padding-left: 0px !important;
}

.chose-info li {
  padding-bottom: 20px;
}

.chose-info li:hover {
  margin-left: 15px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

/* .chose-info li::before { 
    content: url(../../images/back-icon.png);
    position: absolute;
    left: 39px;
} */
.ale_chana {
  float: left;
}
.ale_chanaAr {
  float: right;
}

@media (max-width: 1024px) {
  .more-infornmation-wrap {
    padding: 5% 10%;
  }
  .more-infornmation-wrap .button-wrap {
    padding-bottom: 00px;
  }
}

@media (max-width: 425px) {
  .more-infornmation-wrap {
    padding: 15% 10%;
  }
  .txt_fieldresp .ant-select-selector {
    width: 275px !important;
    float: left;
  }
  .txt_fieldresp .ant-select {
    width: 277px !important;
    float: left;
  }
}
