#update-details-wrap {
    width: 100%;
    float: left;
}


.update-details-modal{
    width:60% !important;
}

.update-details-modal .ant-modal-body {
    background-color: #F6F6F6;
    padding: 5% 8%;
}

.update-details-modal .ant-modal-close {
    outline: none;
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
}
@media (min-width: 320px) and (max-width: 575px){
    .update-details-modal{
        width:90% !important;
    }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
    .update-details-modal{
        width:80% !important;
    }
}