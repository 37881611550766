.mobile-heading{
    color: #404040;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.6px;
    line-height: 22px;
    width: 100%;
    float: left;
    margin-bottom: 6%;
}
.otp-button-wrap-div{
    width: 100%;
    margin-top: 3%;
    padding-bottom: 0;
}

.inputfield{
    width: 100%;
}
.tel_appnuii{
    text-align: left;
    width: 500px!important;
    float: left;
    border-radius: 0px!important;
    height: 48px!important;
    outline: none;
    border: 1px solid #6154540d!important;
    margin-bottom:5%;
}
.tel_appnuiiarabic{
    text-align: left;
    width: 500px!important;
    float: right;
    border-radius: 0px!important;
    height: 48px!important;
    outline: none;
    border: 1px solid #6154540d!important;
    margin-bottom:5%;
}
.fix_otp{
    text-align: left;
    padding-top: 15px;
    color: #606060;
    font-size: 16px;
    font-weight: 600;
}
.inputfield .form-control:focus{
    box-shadow: none;
    background-color: #fff;
}
.inputfield .selected-flag{
    border-right: 1px solid #61545436;
}

.cancel-button {
    box-sizing: border-box;
    padding: 12px;
    width: 225px;
    border: 1px solid #242E49;
    color: #242E49;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.6px;
    line-height: 22px;
    text-align: center;
    background-color: #FFFFFF;
    cursor: pointer;
}

.getotp-button {
    width: 225px;
    padding: 13px;
    background-color: #242E49;
    color: #F6F6F6;
    outline: none;
    border: none;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.6px;
    line-height: 22px;
    text-align: center;
    cursor: pointer;
}

.otp_text_field-change{
    width: 100%;
    float: left;
    text-align: center;
    margin-top: 2%;
}

.change_otp_fld_niu{
    width: 67px!important;
    height: 50px;
    border: 1px solid #EEEEEE;
    outline: none;
    text-align: center!important;
    font-size: 16px;
    font-weight: bold;
    margin-right:10px;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .change_otp_fld_niu{
        width: 40px!important; 
    }
    .cancel-button{
        width: 185px;
    }
    .getotp-button{
        width: 185px;
    }
}

@media (min-width: 320px) and (max-width: 575px){
    .change_otp_fld_niu{
        width: 48px!important;
    }
    .update-details-modal{
        width: 90% !important;
    }
    .editprf_resp{
        padding-top: 10px;
        text-align: center;
        padding-bottom: 7%;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%!important;
    }
    .welcome-text{
        width: 100%;
        text-align: justify;
    }
    .welcome-name{
        text-align: center;
    }
    .prfdet_resp{
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%!important;
    }
    .welcome-div{
        padding: 30px 0px;
    }
    .cancel-button{
        margin-bottom: 25px;
    }
    .tel_appnuii{
        width: 260px!important;
        font-size: 18px!important;
    }
}
