/* NABVAR */
@font-face {
  font-family: "RBBold";
  src: local("RBBold"),
   url("../../fonts/rb-Font/RB-Bold.ttf") format("truetype");
  font-weight: bold;
 }
 @font-face {
  font-family: "RBLight";
  src: local("RBLight"),
   url("../../fonts/rb-Font/rb-light.ttf") format("truetype");
  font-weight: bold;
 }
 @font-face {
  font-family: "RBRegular";
  src: local("RBRegular"),
   url("../../fonts/rb-Font/RB-Regular.ttf") format("truetype");
  font-weight: bold;
 }
.niuli-navbar{
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 1000;
    background: #fff;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 21%); 
}

/* NAVBAR BOX*/

.niuli-navbar-box{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: relative;
    height: 100px;
    width: 94vw;
    margin: auto;
}
.niuli-navbar-box-english{
    direction: ltr;
}
.niuli-navbar-box-arabic{
        direction: rtl;
}

/* NAVBAR MAIN */
.niuli-navbar-main {
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    position: absolute;
  }

.niuli-navbar-main-mobile {
  top: 0;
  display: flex;
  flex-direction: column;
  transform: translate(2%, 50%);
}
.niuli-navbar-main-pc {
  display: flex;
  flex-direction: row;
}

.niuli-navbar-main-english{
  left: 0;
  text-align: left;
}

.niuli-navbar-main-arabic{
  right: 0;
  text-align: right;
}

/* NAVIGATION MENU */
.niuli-nav-menu {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 100vw;
  justify-content: end;
}

.niuli-nav-item {
  display: flex;
  align-items: center;
  height: 60px;
  }
.niuli-nav-links {
  display: block;
  font-weight: 400;
  color: #212121;
  font-size: 15px;
  letter-spacing: 1.44px;
  transition: all .3s ease 0s;
  height: 40px;
  line-height: 40px;
  border: transparent;
  letter-spacing: 1.58px;
  padding: 0 10px;
  }
  
.niuli-nav-links:hover {
  transition: all 0.2s ease-out;
  color: #212121;
  background-color: #eee;
  }

/* LOGIN BUTTON */
.niuli-login-btn{
  font-size: 17px;
  font-weight: 400;
  padding-left: 25px;
  padding-right: 25px;
  border: 1px solid #1f2128;
  outline: none;
  background: none;
  color: #212121;
  overflow: hidden;
  transition: all .3s ease 0s;
  padding-top: 2px;
  padding-bottom: 3px;
}
.niuli-login-btn:hover{
  background: #1f2128;
  color: #fff;
}

/* LANGUAGE SWITCHER */
.niuli-navbar-language-switch{
  padding: 0px 8px;
  border: 3px solid #fccc6a;
  cursor: pointer;
  color: #212121;
}
.niuli-navbar-language-switch:focus{
  color: #fccc6a;
}

.niuli-navbar-language-switch:hover{
  color:#fccc6a;
}

/* ICON BUTTONS */
.niuli-navbar-profile-icon{
  width:2rem;
}

/* CONTRAST COLOR */
.niuli-navbar-contrast-color{
  color: #fccc6a;
}

/* NAVBAR_END */
.niuli-navbar-end{
  position: absolute;
  display: flex;
  flex-direction: row;
  grid-template-columns: repeat(5, auto);
  list-style: none;
  text-align: center;
  justify-content: flex-end;
  align-items: center;
}
.niuli-navbar-end-english{
  right:0;
}
.niuli-navbar-end-arabic{
  left:0;
}
.niuli-navbar-end-pc{
  grid-gap: 20px;
}
.niuli-navbar-end-mobile{
  grid-gap: 16px;
}

/* MENU HAMBURGER ICON */
.niuli-navbar-menu-icon{
  color: #fccc6a;
  font-size: 1.6rem;
}
.niuli-navbar-menu-icon-mobile-div{
  width: 2rem;
}


/* DROPDOWN */
.niuli-dropdown-menu {
  width: 200px;
  position: absolute;
  top: 70px;
  list-style: none;
  text-align: start;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 21%);
}

.niuli-dropdown-mobile {
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 21%);
}
  
.niuli-dropdown-menu li {
  background: white;
  cursor: pointer;
  color: #212121;
}
.niuli-dropdown-menu li:hover {
  background: white;
}
.niuli-dropdown-menu.clicked {
  display: none;
}
  .niuli-dropdown-link {
    display: block;
    width: 100%;
    text-decoration: none;
    color: #212121;
    background: #fff;
    color: #212121;
    padding-left: 20px;
    height: 40px;
    line-height: 40px;
    transition: all .3s ease 0s;
    font-size: 13px;
  }

  .niuli-dropdown-link:hover {
    background: #fafafa ;
    padding-left: 25px ;
    border-left: 1px solid black ;
    color: #fccc6a;
  }
  .niuli-dropdown-li {
    display: block;
    width: 100%;
    text-decoration: none;
    color: #212121;
    background: #fff;
    color: #212121;
    height: 40px;
    line-height: 40px;
    transition: all .3s ease 0s;
    font-size: 15px;
  }
  .niuli-dropdown-li-english {
    padding-left: 20px;
  }
  .niuli-dropdown-li-english:hover {
    background: #fafafa ;
    padding-left: 25px ;
    border-left: 1px solid black ;
    color: #fccc6a;
  }
  .niuli-dropdown-li-arabic {
    padding-right: 20px;
  }
  .niuli-dropdown-li-arabic:hover {
    background: #fafafa ;
    padding-right: 25px ;
    border-right: 1px solid black ;
    color: #fccc6a;
  }

/* LOCATION */
.niuli-navbar-loc{
  display: inline-flex;
}
.niuli-navbar-loc-city{
  display: block;
  font-weight: 600;
  color: #212121;
  font-size: 15px;
  transition: all .3s ease 0s;
  height: 40px;
  line-height: 40px;
  border: transparent;
  cursor: pointer;
  width: fit-content;
  overflow: hidden;
  white-space: nowrap;
  text-decoration-line: underline;
  text-transform: capitalize;
}
.niuli-navbar-loc-city-pc{
  padding: 0 18px;
}
.niuli-navbar-loc-city-mobile{
  padding:0 0px
}
.niuli-navbar-loc-address{
  display: block;
  font-weight: 400;
  color: #212121;
  font-size: 13px;
  transition: all .3s ease 0s;
  height: 40px;
  line-height: 40px;
  border: transparent;
  padding: 0 10px;
  cursor: pointer;
  width: min-content;
  white-space: nowrap;
  letter-spacing: 1.44px;
}
.niuli-navbar-loc-address-pc{
  max-width: 12rem;
  /* max-width: auto; */
  overflow: hidden;
}
.niuli-navbar-loc-address-mobile{
  max-width: 15rem;
  /* max-width: auto; */
  overflow: hidden;
}
.niuli-navbar-loc-change-arrow{
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.niuli-navbar-down-arrow{
  color: #fccc6a;
  font-size: 18px;
}
.niuli-navbar-dropdown-arrow-pc{
  color: #fccc6a;
  padding: 3px;
  font-size: 18px;
}
.niuli-navbar-dropdown-arrow-mobile{
  display: none;
}


/* EXTRA */
.niuli-nav-links-mobile {
  display: none;
}
  
.niuli-nav-display-none{
  display: none !important;
}

.link_nav_def{
  padding-left:5px;padding-right:5px;
}
/* .niuli-nav-links:active{
  border-bottom: 1px solid #000;
}
.niuli-nav-links:focus{
  border-bottom: 1px solid #000;
} */
/* ol, ul {
    list-style: none;
}
li {
    display: inline-block;
    padding: 20px 0 20px;
    vertical-align: middle;
}
.niuli-nav-item a:hover, a:focus, a:active {
    color: #999;
    text-decoration: none;
}
.niuli-nav-item a {
    text-decoration: none;
    transition: color 0.1s, background-color 0.1s;
}
.niuli-nav-item a {
    position: relative;
    display: block;
    padding: 16px 0;
    margin: 0 12px;
    letter-spacing: 1px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 900;
    text-transform: uppercase;
    transition: color 0.1s,background-color 0.1s,padding 0.2s ease-in;
    
}
.niuli-nav-item a::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 3px;
    left: 0;
    height: 3px;
    width: 100%;
    background-color: #000;
    transform-origin: right top;
    transform: scale(0, 1);
    transition: color 0.1s,transform 0.2s ease-out;
}
.niuli-nav-item a:active::before {
    background-color: #000;
}
.niuli-nav-item a:hover::before, a:focus::before {
    transform-origin: left top;
    transform: scale(1, 1);
} */
.fix_circleredDot{
  float: right;
  position: absolute;
}
.notcir{
  color: #FCCC6A;
    float: right;
    position: relative;
    left: -11px;
}
.notcirar{
  color: #FCCC6A;
    float: right;
    position: relative;
    right: -11px;
}
.notcirmob{
  color: #FCCC6A;
    float: right;
    position: relative;
    left: -12px;
    top: -8px;
}
.notcirarmob{
  color: #FCCC6A;
    float: right;
    position: relative;
    left:30px;
    top: -8px;
}
.sat_noti{
  padding-left: 0px;
  padding-right: 6px;
}
.sat_notiarb{
  padding-left: 6px;
  padding-right: 6px;
}
.sat_noti:hover{
  background-color: #fff;
}
.sat_notiarb:hover{
  background-color: #fff;
}
.chat_imagenot{
  width: 30px;
  height: 30px;
}
.hidemob{
  display: none;
}
.heade_Deskhide{
  display: none;
}
.cur_ap{
  cursor: pointer;
}
/* LESS THAN 960 */
@media screen and (max-width: 960px) {
  .niuli-navbar-main-english{
    top:0px;
    left: 0;
    text-align: left;
  }

    .niuli-nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      /* height: 90vh; */
      position: absolute;
      top: 97px;
      left: -100%;
      opacity: 1;
		transition: background 0.36s cubic-bezier(0.32, 0.08, 0.24, 1),
			height 0.56s cubic-bezier(0.32, 0.08, 0.24, 1);
    }
  
    .niuli-nav-menu.active {
      /* background: #fff; */
      background: #eee9e9;
      left: 0;
      opacity: 1;
		transition: background 0.36s cubic-bezier(0.32, 0.08, 0.24, 1),
			height 0.56s cubic-bezier(0.32, 0.08, 0.24, 1);
      z-index: 1;
    }
  
    .niuli-nav-links {
      text-align: center;
      padding: 0rem;
      width: 100%;
      display: table;
    }
    .niuli-nav-links:hover {
     background-color: #eee;
      border-radius: 0;
    }
    .niuli-menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
    .niuli-nav-links-mobile {
      display: block;
      text-align: center;
      padding: 0.5rem;
      margin: 1rem auto;
      border-radius: 4px;
      width: 80%;
      background: black;
      text-decoration: none;
      color: #fff;
      font-size: 1.5rem;
    }
    .niuli-nav-links-mobile:hover {
      background:#fccc6a;
      color: white;
      transition: 250ms;
    }
    .niuli-nav-optional-button {
      display: none;
    }
    .not-in-mobile-class{
      display:none;
    }
    .hidemob{
      display: block;
    }
    .hidedes{
      display:none;
    }
    .niuli-dropdown-menunew{
      width: 200px;
    position: absolute;
    list-style: none;
    text-align: start;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 21%);
    top: 280px;
    left:268px;
    }
    .niuli-dropdown-menunewqr{
      width: 200px;
    position: absolute;
    list-style: none;
    text-align: start;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 21%);
    top:375px;
    left:280px;
    }
    .head_new{
      padding-top:27px;
    }
    .fix_downarrw{
      padding-left:12px;
      padding-right: 12px;
    }
    .heade_Deskhide{
      display: block;
    }
  }
  @media (min-width: 320px) and (max-width: 480px){
    .niuli-dropdown-menunew{
      width: 200px;
    position: absolute;
    list-style: none;
    text-align: start;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 21%);
    top: 280px;
    left: 103px;
    }
    .niuli-dropdown-menunewqr{
      width: 200px;
    position: absolute;
    list-style: none;
    text-align: start;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 21%);
    top:373px;
    left: 103px;
    }
    .head_new{
      padding-top:27px;
    }
    .fix_downarrw{
      padding-left:12px;
      padding-right: 12px;
    }
    .niuli-nav-links:hover {
      background-color:#fff;
       border-radius: 0;
     }
     .heade_Deskhide{
      display: block;
    }
  } 
  