.drawer-class-wrap .notf-order-id{
    color: #606060;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 25px;
}
.drawer-class-wrap .notf-order-idmes{
    color: #F1823B;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 25px;
}

.drawer-class-wrap .notf-contract-status{
    color: #212121;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 25px;
    text-transform: uppercase;
}

.drawer-class-wrap .notf-contract-description{
    /* color: #606060; */
    color:#F1823B;
    
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 25px;
}

.drawer-class-wrap .notification-tile{
    padding-bottom: 15px;
    padding-top: 15px;
    cursor: pointer;
}

/* .drawer-class-wrap .notification-tile:hover{
    background-color: #6060601f;
} */
.tile_grey{
    background-color: #6060601f;margin-top: 15px;
}
.drawer-class .ant-drawer-title{
    color: #343434;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.6px;
    line-height: 35px;
}

.drawer-classarabic  .ant-drawer-title{
    color: #343434;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.6px;
    line-height: 35px;
}
.drawer-classarabic .ant-drawer-close{
    left: 0 !important;
    right: auto;
}
.drawer-classarabic .ant-drawer-close-x{
    float: left;
}