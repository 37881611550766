.no_items{
    text-align: center;
}
.disignmilestonecolor{
    color:#F1823B;
  }
  .productionmilestonecolor{
    color:#9347D6;
  }
  .fittingmilestone{
    color:#3B83F1;
  }
  .kitchenreadymilestone{
    color:#72B968;
  }

  .kitchen-button { 
    margin-left: 'auto'; 
    border-radius: '3px'; 
    color: '#fff'; 
    border: 'none'; 
    border: '1px solid #fff'; 
    margin:'5px'; 
    background-color: 'rgb(35 41 66)'
  }
  .kitchen-button:hover {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.9);
  }
  .kitchen-buttonClick { 
    margin-left: 'auto'; 
    border-radius: '3px solid black'; 
    color: '#000';  
    border: '1px solid #000'; 
    margin:'5px'; 
    background-color: '#fff'
  }

  .kitchen-buttonClick { 
    color: #fff;
    background-color: rgba(0, 0, 0, 0.9);
  }

  @media (min-width: 320px) and (max-width: 575px){
  .order_resp_fix{
    width: 50px;
    height: 50px;
    margin-top: 7px;
    }
    .orders_center{
      text-align: center;
    }
  .dupBtn{
    display: flex;
  }
  }

  /*  */
.app_stdfitter{
    width: 100%;
    float: left;
    padding: 10% 15% 9% 15%;
}
.app_btns{
  text-align: center;
  width: 100%;
}
.app_stdfwrapinside{
    color: #242E49;
    text-align: center;
    width: 100%;
    padding-top: 10%;
    float: left;
    font-weight: 600;
    padding-bottom: 10%;
}
.draft_wrapfitting{
  padding: 0% 13% 0% 13%;
  width: 100%;
  float: left;
  background: #F6F6F6;
}
.mopitstop1{
  width:50%;
  margin-top: 10px;
}
.mopitstop1 .ant-modal-body{
  padding:0px;
}
.mopitstop1 .ant-modal-close{
  outline: none;
  border: none;
}
.mopitstop1 .ant-modal-close-x{
  color: #000;
  font-weight: 600;
  font-size: 30px;
}
.mopitstop1 .ant-modal-wrap{
  position: fixed;
  top: 30%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}

/* for repeat order */

.mopitstop1_repeat{
  width:50% !important;
  margin-top: 10px;
}
.mopitstop1_repeat .ant-modal-body{
  padding:0px;
}
.mopitstop1_repeat .ant-modal-close{
  outline: none;
  border: none;
}
.mopitstop1_repeat .ant-modal-close-x{
  color: #000;
  font-weight: 600;
  font-size: 30px;
}
.mopitstop1_repeat .ant-modal-wrap{
  position: fixed;
  top: 30%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}

.mopitstop1_repeat .ant-modal-body .app_stdfitter {
  padding: 10% 2% 9% 2% !important;
}

.mopitstop1_repeat .ant-modal-body .app_stdfitter .app_stdfwrapinside {
  font-size: 20px;
}